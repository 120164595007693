import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-authheader',
  templateUrl: './authheader.component.html',
  styleUrls: ['./authheader.component.css']
})
export class AuthheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    $(document).ready(function()
    {
        $('.responsive-menu').click(function()
            {
                $('div#menuHead').slideToggle();
            });
    });


  }

}
