import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Httpclient } from '../_services/authorization.service';

declare var $: any; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 
username:string="";
passwords:string="";
  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private http: Httpclient,
    private spinner: SpinnerVisibilityService,
  ) { }

  ngOnInit(): void {
 
  }
  gotoreg() {
    debugger;
   
    this.router.navigate(['/user-registration']);
  }

  
  
  loginclick() {
       
    let data={
      uname:this.username,
      pass:this.passwords
    }
   
    return this.http.post("loginsigin", data).toPromise().then(res => {
     
      this.spinner.show();
      debugger;
      //const parseJson = JSON.stringify(res);
    //const parsedJson = JSON.parse(parseJson);
     var item ={
       username: res[0].id

     }

      localStorage.setItem("_uid", JSON.stringify(item) );
      this.spinner.hide();
      this.router.navigate(['/home']);
      debugger;
    });

   
  
    debugger
    
  }

}
