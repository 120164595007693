import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { ApplayoutComponent } from './layout/applayout/applayout.component';
import { AuthlayoutComponent } from './layout/authlayout/authlayout.component';
import { LoginComponent } from './login/login.component';
import { LpComponent } from './lp/lp.component';
import { PdfGenerateComponent } from './pdf-generate/pdf-generate.component';
import { ReporttempComponent } from './reporttemp/reporttemp.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';

const routes: Routes = [
  {
    path: '',
    component: AuthlayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },  
      
    ]
  }, 
  {
    path: '',
    component: ApplayoutComponent,
    children: [ 
      { path: 'home', component: LpComponent, pathMatch: 'full' }, 
      { path: 'file-Process/:id', component: PdfGenerateComponent, pathMatch: 'full' }, 
      { path: 'user-registration', component: UserregistrationComponent, pathMatch: 'full' },
      { path: 'preview/:id', component: ReporttempComponent, pathMatch: 'full' }, 
    ]
  },
  {
    path: "**",
    redirectTo: ""

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export var AppRoutes = routes;