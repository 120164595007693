import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Httpclient } from '../_services/authorization.service';
import * as jspdf from 'jspdf';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreviewshowComponent } from '../previewshow/previewshow.component';
import { saveAs } from 'file-saver';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-reporttemp',
  templateUrl: './reporttemp.component.html',
  styleUrls: ['./reporttemp.component.css']
})
export class ReporttempComponent implements OnInit {

  @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  @ViewChild(MatTable,{static:true}) table1: MatTable<any>;
  @ViewChild('reportContent') reportContent: ElementRef;
  appiduniq:any;
  propsizenew:string;
  survaernonew:string;
  propaddresnew:string;
  prophousenonew:string;
  westbynew:string;
  eastbynew:string;
  northbynew:string;
  southbynew:string;
  initialdata:firstdivdetails;
  imgURLpatta:any;
  imgURLec2:any;
  EC2imageslist=[];
  imgURLec2b64:any;
  ispreviewshown:boolean;
  preivedatafinal:string;  
  response:any;
  survayno:string;
  plotno:string;
  houseno:string;
  propaddres:string;
  northby:string;
  southby:string;
  eastby:string;
  westby:string;
  nilamcomments:string;
  reuestcoments:string;
  
  Devolution:string;
  Conclusion:string;
  message:any;
  ecsource:string;
  pattasource:string;
  ebsource:string;
  watersource:string;
  propertytaxsource:string;

  parameter:string;
  byec:string;
  bypatta:string;

  recenttransactiondate:string;
  receventmvcvvalue:string;
  doclist2 =[];
  doclist3 = [];
  doclist4=[];
  doclist5 = [];
  doclist6=[];
  doclist7 = [];
  doclist8=[];
  doclist9 = [];
  doclist10=[];
  
  imgURLpatta1:any;
  tablediiferentsource:any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  filedown: Object;
 
  
  constructor(
    private http: Httpclient,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog ,
    private spinner: SpinnerVisibilityService,
  ) { }
  logindetails: any;
  
  ngOnInit(): void {
    this.logindetails = JSON.parse(localStorage.getItem("_uid"));
    this.appiduniq = this.route.snapshot.paramMap.get('id');
    this.ispreviewshown=false
    this.getpreviewfiledetails();
   
  }

  displayedColumns: string[] = [  'docid','textbox1','textbox2', 'textbox3','action'];
  dataSource1 : any;//ELEMENT_DATA;
 
  

  addRowData(action,row_obj){
     
    debugger;
    if(action == 'Add')
    {
      this.dataSource1.push({
        docid:0,
        text1:row_obj.textbox1,
        text2:row_obj.textbox2,
        text3:row_obj.textbox3,
      });
      this.table.renderRows();
    }
  else if(action == 'Update'){
    
    // this.dataSource1 = this.dataSource1.filter((value,key)=>{
    //   if(value.id == row_obj.id){
    //     value.name = row_obj.name;
    //   }
      return true;
    }
  else if(action == 'Delete'){
    // this.dataSource1 = this.dataSource1.filter((value,key)=>{
    //   return value.id != row_obj.id;
    }
  }

 
  // async navigatetopreview() {
  //   this.message = "Fetching..";
  //   this.response = "";
  //   this.response = await this.http.post
  //     <any>(this.apiURL)
  //     .pipe(delay(1000))
  //     .toPromise();
  //   this.message = "Fetched";
  // }
  downloadPdf(base64String, fileName){
    debugger;
    // if(window.navigator && window.navigator.msSaveOrOpenBlob){ 
    //   // download PDF in IE
    //   let byteChar = atob(base64String);
    //   let byteArray = new Array(byteChar.length);
    //   for(let i = 0; i < byteChar.length; i++){
    //     byteArray[i] = byteChar.charCodeAt(i);
    //   }
    //   let uIntArray = new Uint8Array(byteArray);
    //   let blob = new Blob([uIntArray], {type : 'application/pdf'});
    //   window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
    // } else {
      // Download PDF in Chrome etc.
      const source = `data:application/pdf;base64,${base64String}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `${fileName}.pdf`
      link.click();
    }

  navigatetopreview() {
    this.spinner.show();
    let user ={
      //roleid:this.logindetails.username,
      appid:this.appiduniq
      }
  
      debugger;
    return this.http.post("exporttopdftem",user).toPromise().then(res => {
      debugger;

     // this.filedown = res 
      this.downloadPdf(res,"sample");

//       var binaryData = [];
// binaryData.push(res);
// window.URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))
     //  let filename =  "File Name";
     //  saveAs(File, filename);
     this.spinner.hide();
     debugger; 
    });
   
  
  
     
    


    // const dialogConfig = new MatDialogConfig();
    // this.matDialog.open(PreviewshowComponent,{
    //   // width: '250px',
      
    //   data: {appid: this.appiduniq,
        
    //         },
    // });
  }
  
  deleteRowData(action,row_obj,rowid){
     debugger;
      if (rowid > 0) {
        this.dataSource1 = this.dataSource1.filter((item, index) => index !== rowid);
        //this.dataSource1.splice(rowid, 1);
      }
    
  }
  
  

  isshowprecviewmode() {
    debugger;
    this.propsizenew=this.initialdata[0].propsize;     
    this.propaddresnew=this.initialdata[0].propaddres;
    this.prophousenonew=this.initialdata[0].houseno;
    this.survaernonew=this.initialdata[0].survayno;
    this.eastbynew=this.initialdata[0].eastby
  this.northbynew=this.initialdata[0].northby;
  this.southbynew=this.initialdata[0].southby;
  this.westbynew=this.initialdata[0].westby;
this.preivedatafinal=`The property admeasuring an extent of ${this.propsizenew} comprised in Survey Number(s) ${this.initialdata[0].survayno}
bearing house no ${this.initialdata[0].prophousenonew} situated in ${this.propaddresnew} bounded on the North by ${this.initialdata.northby} South by ${this.southbynew}
East by${this.eastbynew}  West by ${this.westbynew}`
  
      this.ispreviewshown = ! this.ispreviewshown;
      
      }

      getpreviewfiledetails()
      {
    
        let user ={
          //roleid:this.logindetails.username,
          appid:this.appiduniq
          }
      
      return this.http.post("getpreviewdetails",user).toPromise().then(res => {
         
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        this.initialdata= parsedJson.data[0]; 
        this.doclist2 = parsedJson.data[2];
        this.dataSource1 = parsedJson.data[1]; 
        this.doclist3 = parsedJson.data[3]; 
        this.doclist4 = parsedJson.data[4]; 
        this.doclist5 = parsedJson.data[5]; 
        this.doclist6 = parsedJson.data[6]; 
        this.doclist7 = parsedJson.data[7]; 
        this.doclist8 = parsedJson.data[8]; 
        this.doclist9 = parsedJson.data[9]; 
        this.doclist10 = parsedJson.data[10];
        this.EC2imageslist = parsedJson.data[11];
        
        debugger;
      });
    
      }
   

      draftpreviewsave(){ 
        this.spinner.show();
        debugger;
        // var fd = new FormData();
        // fd.append('roleid', this.logindetails.username);
        // fd.append('constantdetails', this.doclist3);
        let user ={
          roleid:this.logindetails.username,
          constantdetails: this.initialdata,
          uniqid:this.appiduniq,
          userid:1,
          doc1:this.dataSource1,
          doc2:this.doclist2,
          doc3:this.doclist3,
          doc4:this.doclist4,
          activemortagelist:this.doclist5,
          activeleaselist:this.doclist6,
          EC2imageslist:this.EC2imageslist,
          ec2list:this.doclist10,

          pattaimage:this.imgURLpatta1,
          ec2image:this.imgURLec2
 
          }
          
 
      
      return this.http.post("draftsavepreviewdetails",user).toPromise().then(res => {
         
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        debugger;
        if(parsedJson.data[0]=='Y')
        {
          this.getpreviewfiledetails(); 
          alert("insert sucessfully")
        }
        else{
          alert("insert not sucessfully")
        }      
         
       
        this.spinner.hide();
        
        
      });
      }
     

      addGuideline() {
        const obj = {
          docid:0,
          survarno: '',
          guidlinevalue: '',
          lansclassifg: ''
        }
        this.doclist4.push(obj)
      }
      
      deleteGuideline(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist4.splice(x, 1 );
        }   
      } 

      addEC2() {
        const obj = {
          docid:0,
          excutant: '',
          climant: '',
          regdate: '',
          trandate: '',
          mvcval: '',
          docno: '',
          year: '',
          surveyno: '',
          sro: '',
          schedulenos: ''
        }
        this.doclist10.push(obj)
      }
      
      deleteEC2(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist10.splice(x, 1 );
        }   
      } 

      adddoccolected() {
        const obj = {
          docid:0,
          text1: '',
          text2: '',
          text3: ''           
        };
        this.doclist2.push(obj)
      }
      
      deletedoccolected(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist2.splice(x, 1 );
        }   
      } 

      addTable() {
        const obj = {
          docid:0,
          parameter: '',
          byec: '',
          bypatta: ''
        }
        this.doclist3.push(obj)
      }
      
      deleteRow(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist3.splice(x, 1 );
        }   
      } 

      addClosedLease() {
        const obj = {
          docid:0,
          executantname: '',
          benefiname: '',  
          datedocno:'',       
          dateclose: '',          
          closedocno:''
        }
        this.doclist8.push(obj)
      }
      
      deleteClosedLease(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist8.splice(x, 1 );
        }   
      }
      
      addLitigation() {
        const obj = {
          docid:0,
          casedet: '',
          courtname: '',  
          petionar:'',       
          respondder: '',          
          corstatus:''
        }
        this.doclist9.push(obj)
      }
      
      deleteLitigation(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist9.splice(x, 1 );
        }   
      } 

    

      addClosedMortage() {
        const obj = {
          docid:0,
          executantname: '',
          benefiname: '',  
          datedocno:'',       
          dateclose: '',          
          closedocno:''
        }
        this.doclist7.push(obj)
      }
      
      delteClosedMortage(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist7.splice(x, 1 );
        }   
      } 

      addActiveLease() {
        const obj = {
          docid:0,
          benefiname: '',
          executantname: '',
          sizeoftrans: '',
          date:'',
          docno:'',
          surveynumber:'',
          mvcvvalue:''
        }
        this.doclist6.push(obj)
      }
      
      deleteActiveLease(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist6.splice(x, 1 );
        }   
      } 

      addActiveMortage() {
        const obj = {
          docid:0,
          benefiname: '',
          executantname: '',
          sizeoftrans: '',
          date:'',
          docno:'',
          surveynumber:'',
          mvcvvalue:''
        }
        this.doclist5.push(obj)
      }
      
      deleteActiveMortage(x){
        var delBtn = confirm(" Do you want to delete ?");
        if ( delBtn == true ) {
          this.doclist5.splice(x, 1 );
        }   
      } 
         

      readFile(file: File): Observable<any> {
        const sub = new Subject<any>();
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {           
           
           this.imgURLec2b64 = _event.target.result ;
           let ss=this.imgURLec2b64.split('base64,')[1]
           sub.next(ss);
           sub.complete();
        };
     
        reader.readAsText(file);
        return sub.asObservable();
     }
     
 
       ecchanageimage(files){
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          let message = "Only images are supported.";
          return;
        }
        debugger;
        let ss=""
        const f1=files[0];
         
        var reader = new FileReader();
        let byteArray;

        reader.onload = (_event) => {           
           
          this.imgURLec2b64 = _event.target.result ;
           
       };

  if (files[0]) {
    reader.readAsDataURL(files[0]);
  }
      
       
        const obj = {
          doctype:1,
          docid:0,
          filename:files[0].name,       
          filecontent: this.imgURLec2b64.split('base64,')[1],          
          
        }
        this.EC2imageslist.push(obj)

      }
       

      preview(files1,files,type) {
        if (files.length === 0)
          return;
    
        var mimeType = files[0].type;
        if (mimeType.match(/image\/*/) == null) {
          let message = "Only images are supported.";
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(files[0]); 
        reader.onload = (_event) => { 
         debugger;
         if(type==1)
         {
          this.imgURLpatta = _event.target.result; 
          this.imgURLpatta1=files[0];
         }
         if(type==2)
         {
          this.imgURLec2 = _event.target.result; 
           
       //   let reader = new FileReader();
        //  reader.readAsArrayBuffer(files[0]);
        //  reader.onload = function() {
//const arrayBuffer = reader.result 
        //    var bytes = new Uint8Array(arrayBuffer);

         }
         
        }
      }

      finalsubmit(){
        let user ={
          roleid:this.logindetails.username
        }
       // return this.http.post("savetonextlevel",user).toPromise().then(res => {
         
        //  const parseJson = JSON.stringify(res);
        //  const parsedJson = JSON.parse(parseJson);
             
       // });
      }

}
 


export interface UsersData {
  
  textbox1: string ,
  textbox2:string ,
  textbox3:string 
}
export interface UsersData1 {
  
  textbox1: string ,
  textbox2:string ,
  textbox3:string 
}

export interface firstdivdetails{
  
   sro:string;  
  village:string;
  name:string;
  branch:string
  docno:string;
  docyear:string;
  applicationno:string;
  reuestcoments:string;
  nilamcomments:string;  
  survayno:string;
  plotno:string;
  propsize:string
  houseno:string;
  propaddres:string;
  northby:string;  
  southby:string;
  eastby:string;
  westby:string
  ecsource:string;
  ebsource:string;
  watersource:string
  propertytaxsource:string;
  receventmvcvvalue:string
  recenttransactiondate:string;
}


 
// const ELEMENT_DATA: UsersData[] = [
//   { textbox1: '',textbox2:'' , textbox3:''},
 
   
// ];

// const ELEMENT_DATADOC: UsersData1[] = [
//   { textbox1: '',textbox2:'' , textbox3:''},
 
   
// ];

