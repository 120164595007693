<ng2-toasty [position]="'bottom-right'"></ng2-toasty>

<div class="container-fluid" style="padding: 15px;padding-top: 0px;">

    <div class="row" >
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px;">
            <div class="bcrumbs" style="height: 40px; background-color: #bde7fa;border-radius: 2px;">
               
               <p style="margin-bottom: 0;">
                <i class="fa fa-tachometer" style="margin-left: 15px; margin-top: 10px; color: #000;" aria-hidden="true"> </i>
                <!-- Pdf - Upload Generate -->
                Dashboard / mark relevant transactions
               </p>
              
               

            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 1%">
                
             
            <table class="mat-elevation-z8" style="width:100%">
                <tbody>
                    <tr>
                        <td style="padding: 5px;width: 40%;">NLM12356</td>
                        <td>                              
 

    <div class="mdl-stepper-horizontal-alternative">
        <div class="mdl-stepper-step active-step step-done">
          <div class="mdl-stepper-circle"><span>EC1</span></div>
        
          <div class="mdl-stepper-bar-left"></div>
          <div class="mdl-stepper-bar-right"></div>
        </div>
  
        <div class="mdl-stepper-step">
          <div class="mdl-stepper-circle"><span>EC1</span></div>
      
          <div class="mdl-stepper-bar-left"></div>
          <div class="mdl-stepper-bar-right"></div>
        </div>
        <div class="mdl-stepper-step">
            <div class="mdl-stepper-circle"><span>EC2</span></div>
        
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step">
            <div class="mdl-stepper-circle"><span>EC2</span></div>
        
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step"> 
            <div class="mdl-stepper-circle"><span><mat-icon style="font-size: 14px;line-height: 0px;">file_copy</mat-icon></span></div>
        
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step">
            <div class="mdl-stepper-circle"><span>GV</span></div>
        
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step">
            <div class="mdl-stepper-circle"><span>P</span></div>
        
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          
      </div>
  
     
  
   
                        </td>
                        <td style="text-align: right;padding: 5px;"><button class="btn btn-primary" style="width:33px;padding:3px;font-size: small;">EC1</button>
                            <button class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;" (click)="fileInput.click()">EC2</button>
                            <button class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;"><mat-icon style="font-size: small;height:17px">settings_input_composite</mat-icon></button>
                            <button  class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;"><mat-icon style="font-size: small;height:17px">attach_file</mat-icon></button>
                            <button (click)="showhideappdetails()"   class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;"><mat-icon style="font-size: small;height:17px">compare_arrows</mat-icon></button>
                            <button (click)="showhideappdetailsfull()" class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;"><mat-icon style="font-size: small;height:17px">arrow_drop_down</mat-icon></button>
                            <button class="btn btn-primary" style="width:33px;padding:3px;font-size: small;margin-left: 3px;" (click)="gotopreview()">M</button>
                          </td>
                    </tr>
                </tbody>
            </table>

     
           
        </div>
    </div>
        <div class="row"  *ngIf="!isshowappdetailsfull">
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" >
            <mat-card style="height:170px;overflow-y: auto;padding:0px">
            <table mat-table [dataSource]="table1data" class="table-one"  *ngIf="!isshowappdetails"  >
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  
                  <td mat-cell *matCellDef="let element">{{element.position}}</td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                 
                  <td mat-cell *matCellDef="let element" style="color: darkturquoise;">{{element.name}}</td>
                </ng-container>
              
               
              
                
                <tr mat-row                  
                    *matRowDef="let row; columns: table1columns;"
                ></tr>
              </table>
              <table mat-table [dataSource]="table3data" class="table-one"  *ngIf="isshowappdetails" >
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  
                  <td mat-cell *matCellDef="let element">{{element.position}}</td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                 
                  <td mat-cell *matCellDef="let element" style="color: darkturquoise;">{{element.name}}</td>
                </ng-container>
              
                <tr
                    mat-row
                  
                    *matRowDef="let row; columns: table3columns;"
                ></tr>
              </table>

            </mat-card>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <mat-card style="height:170px;overflow-y: auto;padding:0px" *ngIf="!isshowappdetails">
                <table mat-table [dataSource]="table2data" class="table-one"   >
                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                      
                      <td mat-cell *matCellDef="let element">{{element.position}}</td>
                    </ng-container>
                  
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                     
                      <td mat-cell *matCellDef="let element" style="color: darkturquoise;">{{element.name}}</td>
                    </ng-container>
                  
                    
                    
                    <tr
                        mat-row
                      
                        *matRowDef="let row; columns: table2columns;"
                    ></tr>
                  </table>
 
                </mat-card>
                <mat-card style="height:170px;overflow-y: auto;padding:0px" *ngIf="isshowappdetails">
                   <div style="padding:5px;">
                  <mat-slide-toggle>multiple pledging </mat-slide-toggle> 
                  <button (click)="opendocpopup()" style="float:right;line-height: 25px;padding: 0px 4px; margin-right: 10px;min-width:20px;" mat-raised-button color="primary"><mat-icon>edit</mat-icon></button>
                </div>   
                   
                  <hr style=" margin: 0px;">
                  
                    <table mat-table [dataSource]="table4data" class="table-one"    style="margin-top:10px;">
                      <!-- Position Column -->
                      <ng-container matColumnDef="filename">
                        
                        <td mat-cell *matCellDef="let element"><a href="" >{{element.filename}}</a></td>
                      </ng-container>
                    
                      <!-- <ng-container matColumnDef="docid">
                        
                          <td mat-cell *matCellDef="let element">{{element.docid}}</td>
                        </ng-container> -->
                      
                      <tr
                          mat-row
                        
                          *matRowDef="let row; columns: table4columns;"
                      ></tr>
                    </table>
                  </mat-card>
        </div>
        <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" >
            <mat-card style="height:170px;overflow-y: auto;">
                <button class="btn btn-primary"  *ngIf="!isshowappdetails" style="float: right;border-color: #4CBB17;background: #4CBB17;padding:3px;font-size: small;margin-left: 3px;"><mat-icon style="font-size: small;height:17px">airplanemode_active</mat-icon>mark as query</button>
                <table mat-table [dataSource]="table4data" class="table-one"  *ngIf="isshowappdetails"  style="margin-top:10px;">
                    <!-- Position Column -->
                    <ng-container matColumnDef="filename">
                      
                      <td mat-cell *matCellDef="let element"><a href="" >{{element.filename}}</a></td>
                    </ng-container>
                  
                    <!-- <ng-container matColumnDef="docid">
                      
                        <td mat-cell *matCellDef="let element">{{element.docid}}</td>
                      </ng-container> -->
                    
                    <tr
                        mat-row
                      
                        *matRowDef="let row; columns: table4columns;"
                    ></tr>
                  </table>    
               
                </mat-card>
        </div>
      </div>
              <div class="row" style="padding:10px">
                <div  *ngIf="!isshowpreview" style="width:100%">                
                <div style="border:1px solid green;width:100%;" *ngIf="!isvalidationprocessok1">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="height:50px;background: green;">
                </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" >
              
          <h6>Enquired transactions (EC1)  </h6>


            <mat-table [dataSource]="dataSourceEC1"  >
          
              <!-- ID Column -->
              <ng-container matColumnDef="sro">
                <mat-header-cell *matHeaderCellDef > SRO / Village </mat-header-cell>
                <mat-cell  *matCellDef="let row"> {{row.sro}} </mat-cell>
              </ng-container>
          
              <!-- Progress Column -->
              <ng-container matColumnDef="docno">
                <mat-header-cell *matHeaderCellDef > Doc No </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.docno}}  </mat-cell>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef > year </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.year}} </mat-cell>
              </ng-container>
          
              <!-- Color Column -->
              <ng-container matColumnDef="predocyear">
                <mat-header-cell *matHeaderCellDef > previous year </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.predocyear}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="surveyno">
                <mat-header-cell *matHeaderCellDef  > survey no </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.surveyno}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="transationnature">
                <mat-header-cell *matHeaderCellDef  > transationnature </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.transationnature}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="excutantname">
                <mat-header-cell *matHeaderCellDef  > excutantname </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.excutantname}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="climantname">
                <mat-header-cell *matHeaderCellDef  > climantname </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.climantname}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="scheduleno">
                <mat-header-cell *matHeaderCellDef  > scheduleno </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.scheduleno}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef  > area </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.area}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ploatno">
                <mat-header-cell *matHeaderCellDef  > ploatno </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.ploatno}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsEC1" style="background: cadetblue;"></mat-header-row>
              <mat-row  *matRowDef="let row; columns: displayedColumnsEC1;" style="background: whitesmoke;">
              </mat-row>
            </mat-table>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:20px">
            <mat-tab-group>
              <mat-tab label="all" style="background-color: #3f51b5;"> 
                
            <mat-table [dataSource]="dataSourceEC2"  >
          
              <ng-container matColumnDef="check">
                <mat-header-cell *matHeaderCellDef ><mat-checkbox  [(ngModel)]="checkall"   (change)="checkAllCheckBox(checkall)"></mat-checkbox> </mat-header-cell>
                <mat-cell  *matCellDef="let row">  <mat-checkbox  [(ngModel)]="row.isselect" ></mat-checkbox> </mat-cell>
              </ng-container>
              <!-- ID Column -->
              <ng-container matColumnDef="sro">
                <mat-header-cell *matHeaderCellDef > SRO / Village </mat-header-cell>
                <mat-cell  *matCellDef="let row"> {{row.sro}} </mat-cell>
              </ng-container>
          
              <!-- Progress Column -->
              <ng-container matColumnDef="docno">
                <mat-header-cell *matHeaderCellDef > Doc No </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.docno}}  </mat-cell>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef > year </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.year}} </mat-cell>
              </ng-container>
          
              <!-- Color Column -->
              <ng-container matColumnDef="predocyear">
                <mat-header-cell *matHeaderCellDef > previous year </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.predocyear}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="surveyno">
                <mat-header-cell *matHeaderCellDef  > survey no </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.surveyno}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="transationnature">
                <mat-header-cell *matHeaderCellDef  > transationnature </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.transationnature}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="excutantname">
                <mat-header-cell *matHeaderCellDef  > excutantname </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.excutantname}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="climantname">
                <mat-header-cell *matHeaderCellDef  > climantname </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.climantname}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="scheduleno">
                <mat-header-cell *matHeaderCellDef  > scheduleno </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.scheduleno}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef  > area </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.area}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ploatno">
                <mat-header-cell *matHeaderCellDef  > ploatno </mat-header-cell>
                <mat-cell *matCellDef="let row" > {{row.ploatno}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumnsEC2" style="background: cadetblue;"></mat-header-row>
              <mat-row  *matRowDef="let row; columns: displayedColumnsEC2;" style="background: whitesmoke;">
              </mat-row>
            </mat-table>
                 </mat-tab>
              <mat-tab label="relevant"> 
                <mat-table [dataSource]="dataSourceEC2"  >
          
                  <ng-container matColumnDef="check">
                    <mat-header-cell *matHeaderCellDef ></mat-header-cell>
                    <mat-cell  *matCellDef="let row">  {{$index}}</mat-cell>
                  </ng-container>
                  <!-- ID Column -->
                  <ng-container matColumnDef="sro">
                    <mat-header-cell *matHeaderCellDef > SRO / Village </mat-header-cell>
                    <mat-cell  *matCellDef="let row"> {{row.sro}} </mat-cell>
                  </ng-container>
              
                  <!-- Progress Column -->
                  <ng-container matColumnDef="docno">
                    <mat-header-cell *matHeaderCellDef > Doc No </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.docno}}  </mat-cell>
                  </ng-container>
              
                  <!-- Name Column -->
                  <ng-container matColumnDef="year">
                    <mat-header-cell *matHeaderCellDef > year </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.year}} </mat-cell>
                  </ng-container>
              
                  <!-- Color Column -->
                  <ng-container matColumnDef="predocyear">
                    <mat-header-cell *matHeaderCellDef > previous year </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.predocyear}} </mat-cell>
                  </ng-container>
    
                  <ng-container matColumnDef="surveyno">
                    <mat-header-cell *matHeaderCellDef  > survey no </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.surveyno}} </mat-cell>
                  </ng-container>
    
                  <ng-container matColumnDef="transationnature">
                    <mat-header-cell *matHeaderCellDef  > transationnature </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.transationnature}} </mat-cell>
                  </ng-container>
    
                  <ng-container matColumnDef="excutantname">
                    <mat-header-cell *matHeaderCellDef  > excutantname </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.excutantname}} </mat-cell>
                  </ng-container>
    
                  <ng-container matColumnDef="climantname">
                    <mat-header-cell *matHeaderCellDef  > climantname </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.climantname}} </mat-cell>
                  </ng-container>
    
                  <ng-container matColumnDef="scheduleno">
                    <mat-header-cell *matHeaderCellDef  > scheduleno </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.scheduleno}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="area">
                    <mat-header-cell *matHeaderCellDef  > area </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.area}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="ploatno">
                    <mat-header-cell *matHeaderCellDef  > ploatno </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.ploatno}} </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="displayedColumnsEC2" style="background: cadetblue;"></mat-header-row>
                  <mat-row  *matRowDef="let row; columns: displayedColumnsEC2;"  [class.hidden]="!row.isselect" style="background: whitesmoke;">
                  </mat-row>
                
                
                </mat-table>
                <div style="margin:10px;float:right;">
                <button  style="margin-right:10px" class="btn btn-primary">Continue</button>
               <button class="btn btn-primary" (click)="validationstep1ok()">Done</button>
            </div>
   
              </mat-tab>
              <mat-tab label="suggested"> Content 3 </mat-tab>
            </mat-tab-group>
          </div>
        
        </div>
        <div style="width:100%;margin-top:10px" *ngIf="isvalidationprocessok1">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" >        
             <div class="row">
          <div class="col-sm-5 col-md-5 col-lg-5 col-xl-5">
            <mat-tab-group style="height: 300px;overflow-x:auto;">
              <mat-tab label="relevant transactions" style="background-color: #3f51b5;">
                <mat-accordion class="example-headers-align" >
                  <mat-expansion-panel *ngFor="let value of items;let i = index"  style="background:white" [expanded]="i==0">
                  <mat-expansion-panel-header [collapsedHeight]="'30px'" [expandedHeight]="'30px'"style="background:lightgray;">
                  <mat-panel-title  >
                  {{value.title}} 
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                  {{value.title}} 
                   
                  </mat-panel-description> -->
                     
                  </mat-expansion-panel-header>
                  <div style="height:200px;overflow-x:auto;" >
                  <table style="background:white;width:100%" >
                   
                  <tbody >
                      <tr *ngFor="let x of value.content">           
                          <td>{{ x.key }}</td>
                          <td>{{ x.value }}</td>
                          
                          
                      </tr>
                  </tbody>
                  </table>
                </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </mat-tab>
              <mat-tab label="all transactions" style="background-color: #3f51b5;"> 
              </mat-tab>
            
            </mat-tab-group>
          </div>
          <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7">
            <mat-tab-group style="height: 300px;overflow-x:auto;">
              <mat-tab label="history" style="background-color: #3f51b5;"> 
                <mat-accordion class="example-headers-align" >
                  <mat-expansion-panel *ngFor="let value of itemshis;let i = index" [expanded]="i==0" style="background:white" >
                  <mat-expansion-panel-header [collapsedHeight]="'30px'" [expandedHeight]="'30px'"style="background:lightgray;">
                  <mat-panel-title  >
                    {{value.title}}
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                  {{value.title}} 
                   
                  </mat-panel-description> -->
                     
                  </mat-expansion-panel-header>
                  <div style="height:260px;overflow-x:auto;" >
                  <section class="example-section" style="display: flex;">
                  <div *ngFor="let x of value.head1"  >
                
                      <mat-checkbox style="margin-left:6px;" [(ngModel)]="x.value"> {{x.key}} </mat-checkbox >  
                     
                  </div>
                
                </section>
                <section class="example-section" style="display: flex;">
                <div *ngFor="let y of value.head2" >
                   
                  <mat-slide-toggle style="margin-left:6px;"  [color]="color"
                     [(ngModel)]="y.value">
                  {{y.key}} 
                </mat-slide-toggle>
                  
                 
                </div>
                </section>
               
                  <table style="background:white;width:100%" >   
                  <tbody>
                      <tr *ngFor="let z of value.content">           
                          <td *ngIf="z.istextbox">
                            <div class="form-group">
                              <label >{{z.key}}</label>
                              <input  [(ngModel)]="z.value"  class="form-control" />              
                            </div>
                          </td>
                          <td *ngIf="!z.istextbox">
                            <div class="form-group">
                              <label >{{z.key}}</label>
                              <textarea [(ngModel)]="z.value"  class="form-control" >   </textarea>           
                            </div>
                          </td>
                       
                          
                          
                      </tr>
                  </tbody>
                  </table>
                </div>
                  </mat-expansion-panel>
                  <button  style="margin-top:3px;float:right;">add</button>
                  </mat-accordion>
              </mat-tab>
              <mat-tab label="ownership" style="background-color: #3f51b5;"> 
                <mat-accordion class="example-headers-align" >
                  <mat-expansion-panel style="background:white" [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'30px'" [expandedHeight]="'30px'"style="background:lightgray;">
                  <mat-panel-title  >
                    Based on EC
                  </mat-panel-title>
                  
                  </mat-expansion-panel-header>
                  <div style="height:260px;overflow-x:auto;" >
                                     
                    <table style="background:white;width:100%" >   
                      <tbody>
                          <tr>           
                              <td colspan="2">
                                <div class="form-group">
                                  <label >Current Owner</label>
                                  <input  [(ngModel)]="cowner"  class="form-control" />              
                                </div>
                              </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <div class="form-group">
                                    <label >Seller</label>
                                    <input [(ngModel)]="Seller"  class="form-control" />           
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <div class="form-group">
                                    <label >Property Size</label>
                                    <textarea [(ngModel)]="probsize"  class="form-control"> </textarea>          
                                  </div>
                                </td>
                              </tr>
                          
                              <tr> 
                                <td>
                                  <div class="form-group">
                                    <label >Date Of Sale</label>
                                    <input [(ngModel)]="datesale"  class="form-control" />           
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <label >PropertyNumber type</label>
                                    <input [(ngModel)]="probnumtype"  class="form-control" />           
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <div class="form-group">
                                    <label >surveyNumber</label>
                                    <input [(ngModel)]="surverno"  class="form-control" />           
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td >
                                  <div class="form-group">
                                    <label >Marketvalue</label>
                                    <input [(ngModel)]="marketvalue"  class="form-control" />           
                                  </div>
                                </td>
                                <td >
                                  <div class="form-group">
                                    <label >Considaration value</label>
                                    <input [(ngModel)]="consivalue"  class="form-control" />           
                                  </div>
                                </td>
                              </tr>
                              
                    
                      </tbody>
                      </table>
                
                
                </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel style="background:white" >
                    <mat-expansion-panel-header [collapsedHeight]="'30px'" [expandedHeight]="'30px'"style="background:lightgray;">
                    <mat-panel-title  >
                      Based on Patta
                    </mat-panel-title>
                    
                    </mat-expansion-panel-header>
                    <div style="height:260px;overflow-x:auto;" >
                                       
                      <table style="background:white;width:100%" >   
                        <tbody>
                            <tr>           
                                <td colspan="2">
                                  <div class="form-group">
                                    <label >Current Owner</label>
                                    <input  [(ngModel)]="pattacowner"  class="form-control" />              
                                  </div>
                                </td>
                                </tr>
                              
                                <tr>
                                  <td colspan="2">
                                    <div class="form-group">
                                      <label >Property Size</label>
                                      <input [(ngModel)]="pattaprobsize"  class="form-control" />           
                                    </div>
                                  </td>
                                </tr>
                            
                                <tr> 
                                  <td>
                                    
                                  <td>
                                    <div class="form-group">
                                      <label >Survey No</label>
                                      <input [(ngModel)]="pattasurvayno"  class="form-control" />           
                                    </div>
                                  </td>
                                </tr>
                                <tr> 
                                  <td>
                                    
                                  <td>
                                    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector"  >
                                           drop here 
                                          <button type="button" (click)="openFileSelector()">Browse Files</button>
                                        </ng-template>
                                    </ngx-file-drop>
                                    <div class="upload-table">
                                        <table class="table">
                                             
                                            <tbody class="upload-name-style">
                                                <tr *ngFor="let item of files; let i=index">
                                                    <td><strong>{{ item.relativePath }}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  </td>
                                </tr>
                                
                                
                      
                        </tbody>
                        </table>
                  
                  
                  </div>
                    </mat-expansion-panel>
                  </mat-accordion>
              </mat-tab>
              <mat-tab label="property" style="background-color: #3f51b5;"> 
                <div style="height:260px;overflow-x:auto;padding:7px" >
              
             <table style="background:white;width:100%" >   
              <tbody style="padding:3px">
                <tr>
                  <td colspan="2">
                    street name  <mat-slide-toggle style="margin-left:6px;"  [color]="color"
                    [(ngModel)]="isproperrysuvay">                
               </mat-slide-toggle>  survey number
                  </td>
                </tr>
                  <tr>           
                      <td colspan="2">
                        <div class="form-group">
                          <label >survey number</label>
                          <input  [(ngModel)]="propsurvayno"  class="form-control" />              
                        </div>
                      </td>
                      </tr>
                                      
                      <tr> 
                        <td>
                          <div class="form-group">
                            <label >guidline value</label>
                            <input [(ngModel)]="propguidline"  class="form-control" />           
                          </div>
                        <td>
                          <div class="form-group">
                            <label >land classification</label>
                            <input [(ngModel)]="proplandclassfi"  class="form-control" />           
                          </div>
                        </td>
                      </tr>
                    
              </tbody>
              </table>

                
            </div>
              
   <button style="float: right;margin: 10px;">Add</button>
              </mat-tab>
              <mat-tab label="mortage" style="background-color: #3f51b5;"> 
                <div style="height:260px;overflow-x:auto;padding:7px" >              
                  <table style="background:white;width:100%" >   
                   <tbody style="padding:3px">
                   
                       <tr>           
                           <td colspan="2">
                             <div class="form-group">
                               <label >mortgage beneficiary name</label>
                               <input  [(ngModel)]="mortbenefname"  class="form-control" />              
                             </div>
                           </td>
                           </tr>
                           <tr>           
                            <td colspan="2">
                              <div class="form-group">
                                <label >mortgage executant name</label>
                                <input  [(ngModel)]="mortexecutant"  class="form-control" />              
                              </div>
                            </td>
                            </tr>
                                           
                           <tr> 
                            <tr>           
                              <td colspan="2">
                                <div class="form-group">
                                  <label >mortgage size of transaction</label>
                                  <textarea  [(ngModel)]="mortsizeoftransaction"  class="form-control"> </textarea>             
                                </div>
                              </td>
                              </tr>                                          
                             <tr> 
                             <td>
                               <div class="form-group">
                                 <label >document no</label>
                                 <input [(ngModel)]="propguidline"  class="form-control" />           
                               </div>
                             <td>
                               <div class="form-group">
                                 <label >date of mortgage</label>
                                 <input [(ngModel)]="proplandclassfi"  class="form-control" />           
                               </div>
                             </td>
                           </tr>
                           <tr> 
                            <td>
                              <div class="form-group">
                                <label >Marketvalue</label>
                                <input [(ngModel)]="propguidline"  class="form-control" />           
                              </div>
                            <td>
                              <div class="form-group">
                                <label >Considaration value</label>
                                <input [(ngModel)]="proplandclassfi"  class="form-control" />           
                              </div>
                            </td>
                            <tr>
                              <td colspan="2">
                                active  <mat-slide-toggle style="margin-left:6px;"  [color]="color"
                                [(ngModel)]="ismortactive">                
                           </mat-slide-toggle>  closed
                              </td>
                            </tr>
                          
                   </tbody>
                   </table>
     
                     
                 </div>
              </mat-tab>
              <mat-tab label="litigation" style="background-color: #3f51b5;"> 
                <div style="height:260px;overflow-x:auto;padding:7px" >              
                  <table style="background:white;width:100%" >   
                   <tbody style="padding:3px">                   
                       <tr>           
                           <td colspan="2">
                             <div class="form-group">
                               <label >case details</label>
                               <input  [(ngModel)]="litcasedetails"  class="form-control" />              
                             </div>
                           </td>
                           </tr>
                           <tr>           
                            <td colspan="2">
                              <div class="form-group">
                                <label >courtname & address</label>
                                <input  [(ngModel)]="courtadddress"  class="form-control" />              
                              </div>
                            </td>
                            </tr>
                             
                            <tr>           
                              <td>
                                <div class="form-group">
                                  <label >Pertitioner</label>
                                  <input  [(ngModel)]="Pertitioner"  class="form-control" />            
                                </div>
                              </td>
                              </tr>                                          
                             <tr> 
                             <td>
                               <div class="form-group">
                                 <label >document no</label>
                                 <input [(ngModel)]="propguidline"  class="form-control" />           
                               </div>
                             <td>
                               <div class="form-group">
                                 <label >date of mortgage</label>
                                 <input [(ngModel)]="proplandclassfi"  class="form-control" />           
                               </div>
                             </td>
                           </tr>
                           <tr> 
                            <td>
                              <div class="form-group">
                                <label >Responder</label>
                                <input [(ngModel)]="Responder"  class="form-control" />           
                              </div>
                            </td>
                             
                                <td>
                              <div class="form-group">
                                <label >Current Status</label>
                                <input [(ngModel)]="currentstatusprop"  class="form-control" />           
                              </div>
                            </td>
                          </tr>
                             
                          
                   </tbody>
                   </table>
                   <button style="float:right;" mat-raised-button color="primary" (click)="gotopreview()">Go to Preview</button>
     
                     
                 </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        </div>
     
      </div>
    </div>
      <div style="width:100%;margin-top:10px" *ngIf="isshowpreview">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12"  style="padding-left:3%;padding-right:3%" id="toprintreportpdf">
           <table style="width: 100%;">
             <tbody>
               <tr>
                 <td colspan="3" style="background: lightgray;">
                  Applicant Details
                 </td>
               </tr>
               <tr>
                <td>
                  Name : sathish 
                </td>
                <td>
                  branch : tirunelveli
                 </td>
              </tr>
               <tr>
                <td colspan="3" style="background: lightgray;">
                  property Details
                </td>
              </tr>
              <tr>
                <td>
                  Doc no/Year :3931/2006
                </td>
                <td >
                  SRO: 123
                </td>
                <td >
                  Village: tiruchendur
                </td>
              </tr>
                     
              <tr>
                <td colspan="3" style="background: lightgray;">
                  requestor comments
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  nilam comments
                </td>
              </tr>
            </tbody>
           </table>      
      <p style="font-weight: bold;color: darkturquoise;">Schedule of the Property  <mat-slide-toggle style="margin-left:10px;color:black">preview </mat-slide-toggle> </p>
    <div >
      <table style="width:100%">
        <tbody>
          <tr>
            <td colspan="2">
              <div class="form-group">
                <label >Survey Number</label>
                <input [(ngModel)]="survayno"  class="form-control" />           
              </div>
            </td>
            <td>
              <div class="form-group">
                <label >Property Size</label>
                <input [(ngModel)]="propsize"  class="form-control" />           
              </div>
            </td>
          </tr>
           
          <tr>
            <td>
              <div class="form-group">
                <label> Plot No</label>
                <input [(ngModel)]="plotno"  class="form-control" />           
              </div>
            </td>
            <td>
              <div class="form-group">
                <label >House No</label>
                <input [(ngModel)]="houseno"  class="form-control" />           
              </div>
            </td>
            <td rowspan="2">
              <div class="form-group">
                <label >Property Address</label>
                <textarea  style="height:150px" [(ngModel)]="propaddres"  class="form-control" ></textarea>         
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <label >North by</label>
                <input [(ngModel)]="northby"  class="form-control" />           
              </div>
            </td>
            <td>
              <div class="form-group">
                <label >South by</label>
                <input [(ngModel)]="southby"  class="form-control" />           
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="form-group">
                <label >East By</label>
                <input [(ngModel)]="eastby"  class="form-control" />           
              </div>
            </td>
            <td>
              <div class="form-group">
                <label >west by</label>
                <input [(ngModel)]="westby"  class="form-control" />           
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p style="font-weight: bold;color: darkturquoise;">Devolution of Title</p>
    <angular-editor [(ngModel)]="Devolution" [config]="config"></angular-editor>
    <p style="font-weight: bold;color: darkturquoise;">Conclusion</p>
    <angular-editor [(ngModel)]="Conclusion" [config]="config" [disabled]="true"></angular-editor>
    </div>
      </div>
         </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:20px;display:none">
            <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
        </div> 
        
    
 



    <div style="text-align: center;margin-top: 5px;display: none;">
        <span class="hidden-file">
            <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event)" />
        </span>
        <div class="btn-group" (click)="fileInput.click()">
    
            <button type="button" class="btn btn-primary">
                Upload
            </button>
        </div> 
    
       
    </div>  
</div>
