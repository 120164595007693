              
<div  class="container-fluid" style="padding: 10px 15%">

    <div class="row LoginContainer">

        <div class="col-md-6 Loginsplit">

                  <div class="LoginBox">

                      <img src="https://skenergi.com/imgs/login.png" alt="">

                      <br>

                      <span><img src="http://127.0.0.1:8000/imgs/login.png" alt=""></span>

                  </div>

        </div>

        <div class="col-md-6 Loginsplit">

            <div class="card LoginMainBox">

                <div class="LoginHead">Login</div>

                <p>Enter your email and password to login our dashboard</p>

                <div class="">

                    <form method="POST" action="http://codvotech.in/login">

                        <input type="hidden" name="_token" value="ZjwPkxMJeey6aMTM8P4aUfg2rgHehsanXX1LW5QJ">
                        <div class="form-group" id="form-group">

                            <label for="email" class="col-md-4 col-form-label text-md-left">Email</label>

                                

                            <div class="col-md-12">

                                <input id="email"  type="email" class="form-control " [(ngModel)]="username" name="email" value=""   autocomplete="email" autofocus="">



                                
                            </div>

                        </div>



                        <div class="form-group" id="form-group">

                            <label for="password" class="col-md-4 col-form-label text-md-left">Password</label>



                            <div class="col-md-12">

                                <input id="password" type="password" class="form-control " [(ngModel)]="passwords" name="password"  autocomplete="current-password">



                                
                            </div>

                        </div>



                        <div class="form-group" id="form-group">

                            <div class="">

                                <div class="form-check LoginFormcheck">

                                    <input class="form-check-input" type="checkbox" name="remember" id="remember">



                                    <label class="form-check-label" for="remember">

                                        Keep me logged in
                                    </label>

                                </div>

                            </div>

                            <div class="">

                            
                                    <a style="padding:0; color:#7051f4 !important;" class="btn btn-link" href="#">

                                        Forgot Your Password?
                                    </a>

                                
                            </div>

                        </div>

                        <div class="form-group">

                            <div class="col-md-12">

                                <button type="submit"  class="btn btn-primary"  (click)="loginclick()">

                                    Login
                                </button>

                            </div>

                        </div>

                        <div class="form-group">

                            <div class="">

                                <div class="form-check LoginFormcheck1">

                                    <label class="form-check-label" for="remember">

                                        Dont have an account ?
                                    </label>

                                </div>

                            </div>

                            <div class="">

                            
                                    <a (click)="gotoreg()"   style="padding:0; color:#7051f4 !important;" class="btn btn-link" >

                                        Signup
                                    </a>

                                
                            </div>

                        </div>

                    </form>

                </div>

            </div>

        </div>

    </div>

</div>


 

