<mat-dialog-content>

<div  #reportContent  >
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12"  style="padding-left:3%;padding-right:3%">
  <table style="width: 100%;">
  <tbody>
  <tr>
  <td colspan="3" style="background: lightgray;padding: 6px;">
   Applicant Details
  </td>
  </tr>
  <tr>
  <td style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label >Name</label>
        <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata.name" class="form-control" />
     </div>
  
  </td>
  <td style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label  >branch</label>
        <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata.branch" class="form-control" />
     </div>                        
  </td>
  </tr>
  <tr>
  <td colspan="3" style="background: lightgray;padding: 6px;">
   property Details
  </td>
  </tr>
  <tr>
  <td style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label  >Doc no/Year</label>
        <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].docnoyear" class="form-control" />
     </div>  
   
  </td>
  <td style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label   >SRO</label>
        <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].sro" class="form-control" />
     </div>  
   
  </td>
  <td style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label     >Village</label>
        <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].village" class="form-control" />
     </div>  
   
  </td>
  </tr>
      
  <tr>
  <td colspan="3" style="background: lightgray;vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label> requestor comments</label>
        <input style="margin-left:10px; width: 65%; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].reuestcoments" class="form-control" />
     </div> 
   
  </td>
  </tr>
  <tr>
  <td colspan="3" style="vertical-align: middle;padding: 6px;">
    <div class="form-row">
        <label> nilam comments</label>
        <input style="margin-left:10px; width: 65%; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].nilamcomments" class="form-control" />
     </div> 
   
     
  </td>
  </tr>
  </tbody>
  </table> 
  </div>     
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">Schedule of the Property  </p>
   
  <p>{{preivedatafinal}}</p>
   
  </div> 
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">Devolution of Title</p>
  
  <p style="font-weight: bold;color: darkturquoise;">Conclusion</p>
   
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%;padding-top:3%">
  <p style="font-weight: bold;color: darkturquoise;">List of documents used for scrutiny (scanned,photo copies)</p>
  
  <table class="table table-bordered">
  
          
      <tbody>
      <tr *ngFor="let element1 of doclist; let i = index">    
      
      <td><input type="text"  [(ngModel)]="element1.text1"  class='form-control' ></td>
      <td><input type="text" [(ngModel)]="element1.text2"  class='form-control'></td> 
      <td><input type="text" [(ngModel)]="element1.text3"  class='form-control'></td>          
   
      </tr>
      
      </tbody>
      </table>
   
   
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%;padding-top:3%">
  <p style="font-weight: bold;color: darkturquoise;">List of documents to be collected </p>
    <table class="table table-bordered">
  
          
          <tbody>
          <tr *ngFor="let element of doclist2; let i = index">    
          
          <td><input type="text"  [(ngModel)]="element.text1"  class='form-control' ></td>
          <td><input type="text" [(ngModel)]="element.text2"  class='form-control'></td> 
          <td><input type="text" [(ngModel)]="element.text3"  class='form-control'></td>          
       
          </tr>
          
          </tbody>
          </table>
  
   
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section I - Name from Different sources</p>
  <table class="table table-bordered">
  <thead>
  <tr class="heddercolortables">               
  <th>Source</th>
  <th>Name</th>
  
  </tr>
  </thead>
  <tbody>
  <tr>                 
  <td>E.C</td>
  <td><input type="text" [(ngModel)]="initialdata.ecsource"  class='form-control'></td>              
  </tr>
  <tr>                 
  <td>Patta</td>
  <td><input type="text" [(ngModel)]="initialdata.pattasource"  class='form-control'></td>              
  </tr>
  <tr>                 
  <td>Electricity,board</td>
  <td><input type="text" [(ngModel)]="initialdata.ebsource"  class='form-control'></td>              
  </tr>
  <tr>                 
  <td> Water Tax</td>
  <td><input type="text" [(ngModel)]="initialdata.watersource"  class='form-control'></td>              
  </tr>
  <tr>                 
  <td> Property Tax</td>
  <td><input type="text" [(ngModel)]="initialdata.propertytaxsource"  class='form-control'></td>              
  </tr>
  
  
  </tbody>
  </table>
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section II -  Property Details from Different dource  </p>
  
  <table class="table table-bordered">
  <thead>
  <tr>
  <th class="heddercolortables">parameter</th>
  <th class="heddercolortables">By Ec</th>
  <th class="heddercolortables">By Patta</th>
  <th class="heddercolortables"></th>
  </tr>
  </thead>
  <tr *ngFor="let item of doclist3; let i = index">
  <td><input type="text" [(ngModel)]="item.parameter"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item.byec"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item.bypatta"  class='form-control'></td>
   
  </tr>
  </table>
  
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section III -  Most Recent Transaction Details</p>
  <table class="table table-bordered">
  <thead>
  
  </thead>
  <tbody>
  <tr> 
  <td>transaction date</td>  
  <td><input type="text" [(ngModel)]="initialdata.recenttransactiondate"  class='form-control'></td>                
     
  </tr>
  <tr>  
  <td>MV/CV value</td>  
  <td><input type="text" [(ngModel)]="initialdata.receventmvcvvalue"  class='form-control'></td> 
  </tr>
  
  
  
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section IV - Guideline Value</p>
  <table class="table table-bordered">
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Survey Number/Street Name </th>
  <th>Classsification </th>
  <th>Guideline Value </th>
  
  
  </tr>
  </thead>
  <tbody>
  <tr  *ngFor="let item1 of doclist4; let i = index">  
  <td><input type="text" [(ngModel)]="item1.sno"  class='form-control'></td>               
  <td><input type="text" [(ngModel)]="item1.survarno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item1.guidlinevalue"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item1.lansclassifg"  class='form-control'></td>            
  
  </tr>
  
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section V-A- Active Mortage as per EC</p>
  <table class="table table-bordered">
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Executant Name </th>
  <th>Beneficiciary Name </th>
  <th>Date & Doc.No </th>               
  <th>Survey Number</th>
  <th>MV/CV Value (Rs)</th>
  <th>Size of Transaction</th>             
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item2 of doclist5; let i = index">    
  <td><input type="text" [(ngModel)]="item2.sno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item2.executantname"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item2.benefiname"  class='form-control'></td>  
  <td><input type="text" [(ngModel)]="item2.datedocno"  class='form-control'></td>            
  <td><input type="text" [(ngModel)]="item2.surveynumber"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item2.mvcvvalue"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item2.sizeoftrans"  class='form-control'></td>             
     
  </tr>
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section V-B- Active Lease as per EC</p>
  <table class="table table-bordered">
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Executant Name </th>
  <th>Beneficiciary Name </th>
  <th>Date & Doc.No </th>               
  <th>Survey Number</th>
  <th>MV/CV Value (Rs)</th>
  <th>Size of Transaction</th>             
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item3 of doclist6; let i = index">    
  <td><input type="text" [(ngModel)]="item3.sno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item3.executantname"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item3.benefiname"  class='form-control'></td> 
  <td><input type="text" [(ngModel)]="item3.datedocno"  class='form-control'></td>            
  <td><input type="text" [(ngModel)]="item3.surveynumber"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item3.mvcvvalue"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item3.sizeoftrans"  class='form-control'></td>            
     
  </tr>
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;"> section V-C- Closed Mortage as per EC</p>
  <table class="table table-bordered">
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Executant Name </th>
  <th>Beneficiciary Name </th>
  <th>Date & Doc.No </th>               
  <th>Date of closure</th>
  <th>closure doc.no</th>
     
  </tr>
  </thead>
  <tbody>
     
  <tr *ngFor="let item3 of doclist7; let i = index">    
  <td><input type="text" [(ngModel)]="item3.sno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item3.executantname"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item3.benefiname"  class='form-control'></td>             
  <td><input type="text" [(ngModel)]="item3.datedocno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item3.dateclose"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item3.closedocno"  class='form-control'></td>            
     
  </tr> 
           
  
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section V-D- Closed Lease as per EC</p>
  <table class="table table-bordered">
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Executant Name </th>
  <th>Beneficiciary Name </th>
  <th>Date & Doc.No </th>               
  <th>Date of closure</th>
  <th>closure doc.no</th>
     
  </tr>
  </thead>
  <tbody>
  
  <tr *ngFor="let item8 of doclist8; let i = index">    
  <td><input type="text" [(ngModel)]="item8.sno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item8.executantname"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item8.benefiname"  class='form-control'></td>             
  <td><input type="text" [(ngModel)]="item8.atedocno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item8.dateclose"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item8.closedocno"  class='form-control'></td>            
     
  </tr> 
           
  
  
  </tbody>
  </table>
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section VI Observed Litigation as per EC</p>
  <table class="table table-bordered">
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th>Case Details </th>
  <th>Court Name & Address </th>
  <th>Petitioner</th>               
  <th>Responder</th>
  <th>Current status</th>
     
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item9 of doclist9; let i = index">    
  <td><input type="text" [(ngModel)]="item9.sno"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item9.casedet"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item9.courtname"  class='form-control'></td>             
  <td><input type="text" [(ngModel)]="item9.petionar"  class='form-control' ></td>
  <td><input type="text"  [(ngModel)]="item9.respondder"  class='form-control' ></td>
  <td><input type="text" [(ngModel)]="item9.corstatus"  class='form-control'></td>            
     
  </tr> 
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section VII-A Chain of Ownership</p>
  <table class="table table-bordered">
  
  <tbody>
  <tr>              
  <td></td>       
     
  </tr>
  
  </tbody>
  </table>
  </div>
  
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">section VII-B Detailed History Of TransactionsC</p>
  <table class="table table-bordered">
  
  
  
  
  
  <thead>
  <tr class="heddercolortables">               
  <th>S.No  </th>
  <th> Executant(s) Name</th>
  <th> Claimantt(s) Name </th>
  <th>Registration Date</th>               
  <th>Transaction Date</th>
  <th>MV/CV value (Rs.)</th>
  <th>Doc.No.Year</th>      
  </tr>
  </thead>
  <tbody>
  <tr>                
  <td rowspan="3" style="vertical-align: middle;"><input type="text"   class='form-control'></td>    
  <td><input type="text"   class='form-control'></td>  
  <td><input type="text"   class='form-control'></td>  
  <td><input type="text"   class='form-control'></td> 
  <td><input type="text"   class='form-control'></td>  
  <td><input type="text"   class='form-control'></td>  
  <td><input type="text"   class='form-control'></td>                                
     
  </tr>
  <tr>
  <td colspan="3">
  <div class="form-row">
  <label > Survey Number</label>
  <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   class="form-control" />
  </div>
  </td>
  
  <td colspan="3">
  <div class="form-row">
  <label > SRO</label>
  <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   class="form-control" />
  </div>
  
  </tr>   
  <tr>
  <td>Schedules</td>
  <td colspan="5">
  <input type="text"   class='form-control'></td>
  
  </tr>
       
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">Section VIII Encumbarance Certificate(E.Cs)</p>
  <table class="table table-bordered">
  
  <tbody>
  <tr>              
  <td></td>       
     
  </tr>
  
  </tbody>
  </table>
  </div>
  <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
  <p style="font-weight: bold;color: darkturquoise;">Section IX Patta</p>
  <table class="table table-bordered">
  
  <tbody>
  <tr>              
  <td></td>       
     
  </tr>
  
  </tbody>
  </table>
  </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
   <button mat-button mat-dialog-close>Cancel</button>
   <button mat-button   (click)="downloadPdf()" cdkFocusInitial>Install</button>
 </mat-dialog-actions>