 
import { Component, EventEmitter, OnInit, ViewChild ,ViewEncapsulation} from '@angular/core';
import { FileUploader } from "ng2-file-upload";
import { Httpclient } from '../_services/authorization.service';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import {Subject, Observable, Subscription} from 'rxjs';  
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { DilogtopComponent } from '../dilogtop/dilogtop.component';
import { DilogshowdocumentsComponent } from '../dilogshowdocuments/dilogshowdocuments.component';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
//import {MatAccordion} from '@angular/material/expansion';


declare var $: any;

const URL = "http://localhost:4200/api/imageupload";

export interface EC1datalist {
  
  sro: string;
  docno: string;
  year: string;
  predocyear: string;
  surveyno: string;
  transationnature: string;
  excutantname: string;
  climantname: string;
  scheduleno: string;
  area: string;
  ploatno: string;
}
export interface EC2datalist {
  isselect:boolean;
  sro: string;
  docno: string;
  year: string;
  predocyear: string;
  surveyno: string;
  transationnature: string;
  excutantname: string;
  climantname: string;
  scheduleno: string;
  area: string;
  ploatno: string;
}

@Component({
  selector: 'app-pdf-generate',
  templateUrl: './pdf-generate.component.html',
  styleUrls: ['./pdf-generate.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class PdfGenerateComponent implements OnInit {
  panelOpenState = true;
  appiduniq:any;
 // @ViewChild(MatAccordion) accordion: MatAccordion;
  GblImage:string="";
  defaultimg: boolean; 
  isLinear = true;
  isshowappdetails: boolean = false
  isshowappdetailsfull: boolean = false
  isvalidationprocessok1: boolean = false
  isvalidationprocessok2: boolean = false
  isshowpreview: boolean = false
  Devolutiontittle='fdfdf fdfd';
  htmlContent = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  logindetails: any;
 
  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog  ) { }

  ngOnInit(): void {
    this.appiduniq = this.route.snapshot.paramMap.get('id');
    this.logindetails = JSON.parse(localStorage.getItem("_uid"));
    this.getfiledetails()
    this.dataSourceEC1 = this.ELEMENT_DATAEC1
    this.dataSourceEC2 = this.ELEMENT_DATAEC2
  }

  getfiledetails(){

 
    debugger;
    let user={     
      appno: this.appiduniq,
      roleid:this.logindetails.username

    }
    return this.http.post("getalldetailssinglefiles",user).toPromise().then(res => {
     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
     
    
      debugger;
    });
  }

  table1columns: string[] = ['position','name'];
  table1data     =  [
    {position: 'DocumentNo', name: '123456'},
    {position: 'Document Year', name: '1989' },
    {position: 'State', name: 'tamilnadu' },
    {position: 'Zone', name: 'tirunelveli' },
    {position: 'District', name: 'tuticorin' },
    {position: 'SRO', name: '123'},
    {position: 'Village', name: 'tiruchendur'},
    
  ];

  
  table2columns: string[] = ['position','name'];
  table2data     =  [
    {position: 'Street', name: 'eastcarsteet'},
    {position: 'survey no', name: '49' },
    {position: 'Property no', name: '2324' },
    {position: 'patta no', name: '567' },
  
    
  ];

  table3columns: string[] = ['position','name'];
  table3data     =  [
    {position: 'Application Number ', name: 'NILS-123456'},
    {position: 'Owner Name', name: 'test' },
    {position: 'applicant Name', name: 'applicant' },
    {position: 'Branch', name: 'tiruchi' },
    {position: 'Email id', name: 'ss@gm.com' },
    {position: 'mobile', name: '123'},
    {position: 'MODT Number', name: '12355'},
    {position: 'MODT year', name: '1994'},
  ];

  table4columns: string[] = ['filename'];
  table4data     =  [
    {filename: 'seed.pdf', docid: '1'},
    {filename: 'patta.pdf', docid: '2' },
    {filename: 'doc1', docid: '3' },
  
  ];



  public uploader: FileUploader = new FileUploader({
    url: URL,
    disableMultipart : false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment',
    allowedFileType: ['image']


    });

  public onFileSelected(files: EventEmitter<File[]>) {
    debugger
    if ((files[0].type == "application/pdf" ) && files[0].size <= 1000000000) {
 
      const formData: FormData = new FormData();
      formData.append("UserId", "1");
      formData.append("file", files[0], files[0]['name']);

      return this.http.post('imageupload', formData).toPromise().then(res => {
        debugger;
        const parseJson = JSON.stringify(res);
        const parsedJson = JSON.parse(parseJson);
        if (parsedJson.message == "Success") {
          this.GblImage = parsedJson.path;
          var toastOptions: ToastOptions = {
            title: "Alert",
            showClose: true,
            timeout: 3000,
            theme: 'material',
            onAdd: (toast: ToastData) => {
            },
            onRemove: function (toast: ToastData) {
            }
          };
          this.defaultimg = false;
          toastOptions.msg = "Image has been uploaded successfully";
          this.toastyService.success(toastOptions);
        }
        else {
          var toastOptions: ToastOptions = {
            title: "Alert",
            showClose: true,
            timeout: 3000,
            theme: 'material',
            onAdd: (toast: ToastData) => {
            },
            onRemove: function (toast: ToastData) {
            }
          };
          toastOptions.msg = "Image not uploaded!";
          this.toastyService.error(toastOptions);
        } 

      })
    }
    else {
      if (files[0].size >= 1000001) {
        var toastOptions: ToastOptions = {
          title: "Alert",
          showClose: true,
          timeout: 3000,
          theme: 'material',
          onAdd: (toast: ToastData) => {
          },
          onRemove: function (toast: ToastData) {
          }
        };
        toastOptions.msg = "Image size not more than 1MB";
        this.toastyService.error(toastOptions);
      }
      else {
        var toastOptions: ToastOptions = {
          title: "Alert",
          showClose: true,
          timeout: 3000,
          theme: 'material',
          onAdd: (toast: ToastData) => {
          },
          onRemove: function (toast: ToastData) {
          }
        };
        toastOptions.msg = "File type not allowed to upload";
        this.toastyService.error(toastOptions);
      }

    }
  }

  checkAllCheckBox(value) {
    debugger;
		this.ELEMENT_DATAEC2.forEach(x => x.isselect = value)
	}
  imageremove() { 
    var toastOptions: ToastOptions = {
      title: "Alert",
      showClose: true,
      timeout: 3000,
      theme: 'material',
      onAdd: (toast: ToastData) => {
      },
      onRemove: function (toast: ToastData) {
      }
    };
    const Userdetails = { 
      UserId: "1"
    };

    return this.http.post('imagedelete', Userdetails).toPromise().then(res => {     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);
      if (parsedJson.data[0][0].Msg == "Success") {
        this.GblImage = "https://stocksherpa.blob.core.windows.net/userimages/img_avatar.png";
        this.defaultimg = true;
        toastOptions.msg = "Image has been removed successfully";
        this.toastyService.success(toastOptions);
      } 
   }).catch(err => { 
        toastOptions.msg = "An error occurred while saving the record, Please try again later";
        this.toastyService.error(toastOptions); 
        return err;
      });
    }

    opendocpopup(): void {
       
        const dialogConfig = new MatDialogConfig();
        this.matDialog.open(DilogshowdocumentsComponent,{
          // width: '250px',
          data: {id: 5},
        });
        
        }


    showhideappdetails() {
      debugger;

        this.isshowappdetails = ! this.isshowappdetails;
        
        }
      
        showhideappdetailsfull() {
          debugger;
    
            this.isshowappdetailsfull = ! this.isshowappdetailsfull;
            
            }

            validationstep1ok(){
              this.isvalidationprocessok1=!this.isvalidationprocessok1;
            }           
            

            displayedColumnsEC1 = ['sro', 'docno', 'year', 'predocyear', 'surveyno', 
            'transationnature','excutantname','climantname','scheduleno','area','ploatno'];
 
            dataSourceEC1: any;
            ELEMENT_DATAEC1: EC1datalist[] = [
              {sro:'SFS22SAF',docno:'TATA',year:"2015",predocyear:'2015',surveyno:'a1',
              transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
              scheduleno: '123',area: '1234567890',ploatno: '15',
            }                         
            ];
            displayedColumnsEC2 = ['check','sro', 'docno', 'year', 'predocyear', 'surveyno', 
            'transationnature','excutantname','climantname','scheduleno','area','ploatno'];
 
            dataSourceEC2: any;
            ELEMENT_DATAEC2: EC2datalist[] = [
              {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
              transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
              scheduleno: '123',area: '12',ploatno: '15',
            },
            {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
            transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
            scheduleno: '123',area: '12',ploatno: '15',
          },
          {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
          transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
          scheduleno: '123',area: '12',ploatno: '15',
        },
        {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
        transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
        scheduleno: '123',area: '12',ploatno: '15',
      },
      {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
      transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
      scheduleno: '123',area: '12',ploatno: '15',
    },
    {isselect:false, sro:'SFS22SAF',docno:'123',year:"2015",predocyear:'2015',surveyno:'a1',
    transationnature: 'sathish',excutantname: 'sundar',climantname: 'none',
    scheduleno: '123',area: '12',ploatno: '15',
  },
                                     
 ];
 

 
item1=[{
  "key":"seller",
  "value":"sathish"  
},
{
  "key":"buyer",
  "value":"saravana"  
},
{
  "key":"dor",
  "value":"value"  
},
{
  "key":"doe",
  "value":"1956"  
},
 
{
  "key":"docyear",
  "value":"2021"  
},
{
  "key":"propertynature",
  "value":"normal"  
},
{
  "key":"considerationvalue",
  "value":"120"  
},
{
  "key":"previousdoc",
  "value":"na"  
},
{
  "key":"surveyno",
  "value":"123"  
},
{
  "key":"street",
  "value":"car street"  
},
{
  "key":"sro",
  "value":"134"  
},
{
  "key":"village",
  "value":"kangeyam"  
},

]

  items = [
    {
      title: '2012|123|122',
      description: 'desc1',
      content:  this.item1,
    },
    {
      title: '012|123|123',
      description: 'desc1',
      content:  this.item1,
    }
    
  ];
 

  
  tittls1his=[{
    "key":"owner",
    "checkbox":true,
    "value":false 
  },
  {
    "key":"mortage",
    "checkbox":true,
    "value":false 
  },
  {
    "key":"lease",
    "checkbox":true,
    "value":false  
  },   
  
]
tittls2his=[{
  "key":"Transactionofsource",  
  "value":false 
},
{
  "key":"Docnoavilable",  
  "value":false 
},  

]


contenthis=[{
  "key":"executant(s)",
  "value":"sathish"  ,
  "istextbox":true
},
{
  "key":"claimant(s)",
  "value":"muthu"  ,
  "istextbox":true
},
{
  "key":"survey no",
  "value":"123" , 
  "istextbox":true
},
{
  "key":"schedule summary",
  "value":"12 12 12" ,
  "istextbox":false 
}, 
 

]

  itemshis = [
    {
      title:  "2012|123|122",    
      head1:  this.tittls1his,  
      head2:  this.tittls2his,  
      content:  this.contenthis,
    },
  ]
     
 
  
  
  public files: NgxFileDropEntry[] = [];
 
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
 
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
 
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
 
          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)
 
          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })
 
          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/
 
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
 
  public fileOver(event){
    console.log(event);
  }
 
  public fileLeave(event){  
    console.log(event);
  }

  gotopreview(){
    debugger;
    this.router.navigate(['/preview/'+this.appiduniq]);
    //this.isshowpreview=!this.isshowpreview;
  }

            
}


