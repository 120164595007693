import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { Httpclient } from '../_services/authorization.service';
import {ToastyService, ToastyConfig, ToastOptions, ToastData} from 'ng2-toasty'; 
import {Subject, Observable, Subscription} from 'rxjs';  
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DilogtopComponent } from '../dilogtop/dilogtop.component';
import { DashboardService } from '../dashboard.service';
import { GetdashboardService } from '../_services/getdashboard.service';

declare var $: any;

const URL = "http://localhost:4200/api/imageupload";

 

@Component({
  selector: 'app-lp',
  templateUrl: './lp.component.html',
  styleUrls: ['./lp.component.css']
})



export class LpComponent implements OnInit {
  GblImage:string="";
  defaultimg: boolean; 
  applicationno: string; 
 

  isshowfilter: boolean = false

  showfileierdiv() {

    this.isshowfilter = ! this.isshowfilter;
    
    }
 
    displayedColumns = ['uniqid', 'ApplicationNumber', 'Client','State','Applicant','Branch','Mobile','Status','assignstatus'];
 
    dataSource: any; 

  
 

 

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  logindetails: any;

  constructor(private http: Httpclient,
    private toastyService:ToastyService, 
    private toastyConfig: ToastyConfig,
    private route: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog ,
    private dservice : GetdashboardService
       ) {
      
      
     }

  ngOnInit(): void {

    debugger
    this.logindetails = JSON.parse(localStorage.getItem("_uid"));
   
    debugger
    this.totalfiles();
    
    
    //his.dataSource = this.ELEMENT_DATA
     
  } 


  ngAfterViewInit() {
   
  }

  

  totalfiles()
  {

    let user ={
      roleid:this.logindetails.username,
    
      }
  
  return this.http.post("allsubmittedfiles",user).toPromise().then(res => {
     
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    this.dataSource = parsedJson.data; 
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  
    debugger;
  });

  }
  navigate(unid,appnonew): void {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(DilogtopComponent,{
      // width: '250px',
      
      data: {id: unid,
        appnos:appnonew
            },
    });
    // const dialogRef = this.dialog.open(DilogtopComponent, {
    //   width: '250px',
    //   data: {name: this.name, animal: this.animal},
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.animal = result;
    // });
  }

  

 

  

    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
    }

    dropdown1: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd1: string = "GB";
    dropdown2: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd2: string = "GB";
    dropdown3: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd3: string = "GB";
  
    dropdown4: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd4: string = "GB";
  
    dropdown5: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd5: string = "GB";
    dropdown6: any = [
      {
        full: "Value1",
        short: "GB"
      },
      {
        full: "Value2",
        short: "US"
      },
      {
        full: "Value 3",
        short: "CA"
      }
    ];
    selectedd6: string = "GB";
    
  }
  
  



 