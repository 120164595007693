import { Component, OnInit,Inject } from '@angular/core'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Httpclient } from '../_services/authorization.service';

 
export interface DialogData {
  id: number,
  appnos:string
  
}
@Component({
  selector: 'app-dilogtop',
  templateUrl: './dilogtop.component.html',
  styleUrls: ['./dilogtop.component.css']
})

export class DilogtopComponent implements OnInit {
  logindetails: any;

  constructor( private router: Router,
    private http: Httpclient,
    public dialogRef: MatDialogRef<DilogtopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit(): void {  
    this.logindetails = JSON.parse(localStorage.getItem("_uid"));
  }
  close() {
    this.dialogRef.close();
  }

  gotoinprocess() {

    let user={     
      appno: this.data.id,
      applicationno: this.data.appnos,
      roleid:this.logindetails.username

    }
    
    return this.http.post("asigntodrafing",user).toPromise().then(res => {
     
      const parseJson = JSON.stringify(res);
      const parsedJson = JSON.parse(parseJson);

      this.router.navigate(['/file-Process/'+this.data.id]);
       this.dialogRef.close();
    
      debugger;
    });
    
  }

}
