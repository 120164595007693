import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetdashboardService {

  constructor(private http: HttpClient) { }
  getData1() : Promise<any>{
    return this.http.get('https://jsonplaceholder.typicode.com/todos').
    toPromise();
    //https://jsonplaceholder.typicode.com/todos
  }

 
 
    // getData() : Promise<any>{
    //     debugger;
    //     let user ={
    //         roleid:1
             
    //         };
    //         return this.http.post("allsubmittedfiles",user).toPromise();
    //    // return this.http.get('allsubmittedfiles",user').
    //   //  toPromise();
    //     //https://jsonplaceholder.typicode.com/todos
    //   }

      
    
// return this.http.post("allsubmittedfiles",user).subscribe(res => {
   
//   const datas=res as submitteddata[];
//   this.dataSource.data= res as submitteddata[];
 
//   debugger;
// });

}
