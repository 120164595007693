 <div class="container-fluid">
     <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" >
        <h5>user registration</h5>
        <hr>       
    </div>
   
            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                <div class="form-group">
                    <label >Applicant Name</label>
                    <input [(ngModel)]="appname" type="text" class="form-control" class="form-control" />           
                  </div>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                    <div class="form-group">
                        <label >Phone</label>
                        <input [(ngModel)]="appphone" type="text" class="form-control" class="form-control" />           
                      </div>
                    </div>
                    <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                        <div class="form-group">
                            <label >Document Number</label>
                            <input [(ngModel)]="docnumber" type="text" class="form-control" class="form-control" />           
                          </div>
                        </div>
                        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                            <div class="form-group">
                                <label >Document Year</label>
                                <input [(ngModel)]="docyear" type="text" class="form-control" class="form-control" />           
                              </div>
                            </div>
                            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                <div class="form-group">
                                    <label >Zone</label>
                                    <input [(ngModel)]="Zone" type="text" class="form-control" class="form-control" />           
                                  </div>
                                </div>
                                <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                    <div class="form-group">
                                        <label >District</label>
                                        <input [(ngModel)]="District" type="text" class="form-control" class="form-control" />           
                                      </div>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                        <div class="form-group">
                                            <label >SRO</label>
                                            <input [(ngModel)]="SRO" type="text" class="form-control" class="form-control" />           
                                          </div>
                                        </div>
                                        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                            <div class="form-group">
                                                <label >Village</label>
                                                <input [(ngModel)]="Village" type="text" class="form-control" class="form-control" />           
                                              </div>
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                                <div class="form-group">
                                                    <label >S.No</label>
                                                    <input [(ngModel)]="sno" type="text" class="form-control" class="form-control" />           
                                                  </div>
                                                </div>
                                                <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > 
                                                    <div class="form-group">
                                                        <label >state</label>
                                                        <input [(ngModel)]="state" type="text" class="form-control" class="form-control" />           
                                                      </div>
                                                    </div>
                                                    <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" > >
                                                       <button (click)="sampleuserinsert()">insert</button>
                                                    </div>

</div>
 </div>