import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

export interface UsersData {
  filename: string,
  textbox1: string ,
  textbox2:string ,
  textbox3:string 
}
export interface DialogData {
  id: string
  
}

const ELEMENT_DATA: UsersData[] = [
  {filename: 'seed.pdf', textbox1: 'settlement seed',textbox2:'3331 of 2221' , textbox3:'7 dec 2021'},
  {filename: 'patta.pdf', textbox1: 'online ec',textbox2:'3331 of 2221',textbox3:'7 dec 2021'},
   
];


@Component({
  selector: 'app-dilogshowdocuments',
  templateUrl: './dilogshowdocuments.component.html',
  styleUrls: ['./dilogshowdocuments.component.css']
})
export class DilogshowdocumentsComponent implements OnInit {
 
  @ViewChild(MatTable,{static:true}) table: MatTable<any>;
  constructor(public dialogRef: MatDialogRef<DilogshowdocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  displayedColumns: string[] = ['filename', 'textbox1','textbox2', 'textbox3','action'];
  dataSource1 = ELEMENT_DATA;

  addRowData(action,row_obj){
     
    debugger;
    if(action == 'Add')
    {
      this.dataSource1.push({
        filename:row_obj.filename, 
        textbox1:row_obj.textbox1,
        textbox2:row_obj.textbox2,
        textbox3:row_obj.textbox3,
      });
      this.table.renderRows();
    }
  else if(action == 'Update'){
    
    // this.dataSource1 = this.dataSource1.filter((value,key)=>{
    //   if(value.id == row_obj.id){
    //     value.name = row_obj.name;
    //   }
      return true;
    }
  else if(action == 'Delete'){
    // this.dataSource1 = this.dataSource1.filter((value,key)=>{
    //   return value.id != row_obj.id;
    }
  }

  
  deleteRowData(action,row_obj,rowid){
     debugger;
      if (rowid > 1) {
        this.dataSource1 = this.dataSource1.filter((item, index) => index !== rowid);
        //this.dataSource1.splice(rowid, 1);
      }
    
  }
     
    
  

}
 