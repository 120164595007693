 
    <div class="flex justify-center sm:items-center sm:justify-between fixed-top bg-white" style="padding:10px !important" id="headalign">

        <div class="text-center text-sm text-gray-500 sm:text-left" id="headLogo">
<a href="index.htm">
            <img src="../../../assets/images/nilaamLogo.png" alt="siteLogo">
</a> 
        </div>

        <div class="ml-4 text-center text-sm text-gray-500 sm:text-right sm:ml-0" id="headRight">

          <div class="text-center text-sm text-gray-500 sm:text-right" id="headGrey">

            <a href="#" class="ml-1 font-nilabold">

                 (+91)72008 20070

            </a>&nbsp;&nbsp;

            <a href="#" class="ml-1 font-nilabold">

                info@nilamlegal.com

            </a>

          </div>
          <div class="responsive-menu">
              <img src="../../../assets/images/menu.png" alt="siteLogo">

          </div>
          <div class="flex items-center" id="menuHead">

              <a href="index.htm" class="ml-1 font-nilabold">

                   Home

              </a>&nbsp;&nbsp;

              <a href="About-Us.html" class="ml-1 font-nilabold">

                  About Us

              </a>&nbsp;&nbsp;

              <a href="WhatWeDo.html" class="ml-1 font-nilabold">

                What We Do

              </a>&nbsp;&nbsp;

              <a href="NLProperty-Report.html" class="ml-1 font-nilabold">

                NL Property Report

              </a>

          </div>

        </div>

    </div>