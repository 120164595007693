<h2 mat-dialog-title>List of documents used for scrutiny (scanned,photo copies) </h2>
<p>Doc/year:3206/2019 - sro:kuluthalai - village:vaikainallur</p>

<mat-dialog-content class="mat-typography">
    <!-- app.component.html -->


  <button mat-button  (click)="addRowData('Add',{})"  mat-flat-button color="primary">Add Row</button>

  <table mat-table [dataSource]="dataSource1" #mytable class="my-table mat-elevation-z8" style="width:100%">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Id Column -->
    <ng-container matColumnDef="filename">
      
      <td mat-cell *matCellDef="let element" > {{element.filename}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="textbox1">      
      <td mat-cell *matCellDef="let element" style="padding:20px">   <input type="text" [(ngModel)]="element.textbox1"  class='form-control'> </td>
    </ng-container>
    <ng-container matColumnDef="textbox2">      
      <td mat-cell *matCellDef="let element" style="padding:20px"><input type="text" [(ngModel)]="element.textbox2"  class='form-control'> </td>
    </ng-container>
    <ng-container matColumnDef="textbox3">      
      <td mat-cell *matCellDef="let element" style="padding:20px"> <input type="text" [(ngModel)]="element.textbox3"  class='form-control'> </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      
      <td mat-cell *matCellDef="let element; let j = index;" class="action-link" style="padding:20px"> 
        <!-- <a (click)="openDialog('Update',element)">Edit</a> |  -->
        <a (click)="deleteRowData('Delete',element,j)"><mat-icon>delete</mat-icon></a>  
      </td>
    </ng-container>

  
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-stroked-button  color="warn" mat-dialog-close cdkFocusInitial>cancel</button>
  <button mat-button mat-stroked-button color="primary" [mat-dialog-close]="true" >save</button>
</mat-dialog-actions>
