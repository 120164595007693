import { Statement } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Httpclient } from '../_services/authorization.service';

@Component({
  selector: 'app-userregistration',
  templateUrl: './userregistration.component.html',
  styleUrls: ['./userregistration.component.css']
})
export class UserregistrationComponent implements OnInit {

  appname:string;
  appphone:string;
  docnumber:string;
  docyear:string;
  Zone:string;
  District:string;
  SRO:string;
  Village:string;
  sno:string;
  state:string;
  constructor(private route: ActivatedRoute,
    private router: Router, 
    private http: Httpclient) { }

  ngOnInit(): void {
  }

  sampleuserinsert() {
    let data ={
      appname:this.appname,
      state:this.state,
      appphone:this.appphone,
      docnumber:this.docnumber,
      docyear:this.docyear,
      Zone:this.Zone,
      District:this.District,
      SRO:this.SRO,
      Village:this.Village,
      sno:this.sno,
      BankName:"icici",
      BankBranch:"chennai"
      
    }
 
    return this.http.post("userreg", data).toPromise().then(res => {
     
      this.router.navigate(['/home']);
      debugger;
    });
  }

}
