<div id="footerLast" class="container-fluid" style="padding: 2em 1em 2em 1em; background: #fafafa;">

    <div class="footerLeft">

        <div class="">

            <a href="#" class="ml-1 font-nilabold">

                Nilam Home

            </a>&nbsp;|&nbsp;

            <a href="#" class="ml-1 font-nilabold">

                About Us

            </a>&nbsp;|&nbsp;

            <a href="#" class="ml-1 font-nilabold">

              What We Do

            </a>&nbsp;|&nbsp;

            <a href="#" class="ml-1 font-nilabold">

              Terms & Conditions

            </a>&nbsp;|&nbsp;

            <a href="#" class="ml-1 font-nilabold">

              Privacy Policy

            </a>

        </div>

        <div class="">

          @2021, Nilam Legal Solution.  All Rights Reserved.

        </div>

    </div>

    <div class="footerRight">

        <div style="float: right;">

          <p>Nilam Legal is also in</p>

          <span class="footericons"><img src="../../../assets/images/linkedin.png" style="width:18px; height:16px;" alt="Linkedin"></span>

          <span class="footericons"><img src="../../../assets/images/twitter.png" style="width:18px; height:16px;" alt="Twitter"></span>

          <span class="footericons"><img src="../../../assets/images/FB.png" style="width:18px; height:16px;" alt="FB"></span>

        </div>

    </div>

</div>