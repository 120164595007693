import { Component, OnInit } from '@angular/core';
import { Httpclient } from '../_services/authorization.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private http: Httpclient) { }

  ngOnInit(): void {
    this.test();
  }

  test()
  {
    let user ={
     

    }
    return this.http.post("TestSelect", user).toPromise().then(res => {
      alert("Hi");
      debugger;
    });
  }

}
