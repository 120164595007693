import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component'; 
import { Httpclient } from './_services/authorization.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthfooterComponent } from './layout/authfooter/authfooter.component'; 
import { AuthheaderComponent } from './layout/authheader/authheader.component';
import { AuthlayoutComponent } from './layout/authlayout/authlayout.component';
import { LpComponent } from './lp/lp.component';
import { LoginComponent } from './login/login.component';

import { FileUploadModule } from 'ng2-file-upload';
import {ToastyModule} from 'ng2-toasty'; 
import { ApplayoutComponent } from './layout/applayout/applayout.component';
import { AppheaderComponent } from './layout/appheader/appheader.component';
import { AppfooterComponent } from './layout/appfooter/appfooter.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import { PdfGenerateComponent } from './pdf-generate/pdf-generate.component';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button'; 
import {MatNativeDateModule} from '@angular/material/core'
import {MatInputModule} from '@angular/material/input';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularEditorModule } from '@kolkov/angular-editor';
import {MatSelectModule} from '@angular/material/select';;
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatDialogModule} from '@angular/material/dialog';

import { DilogtopComponent } from './dilogtop/dilogtop.component';
import { DilogshowdocumentsComponent } from './dilogshowdocuments/dilogshowdocuments.component';
import {MatTabsModule} from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UserregistrationComponent } from './userregistration/userregistration.component';
import { ReporttempComponent } from './reporttemp/reporttemp.component';
import { PreviewshowComponent } from './previewshow/previewshow.component';
import { NgHttpLoaderModule } from 'ng-http-loader';

 
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    AuthheaderComponent,
    AuthfooterComponent,
    AuthlayoutComponent,
    LpComponent,
    LoginComponent, 
    ApplayoutComponent, AppheaderComponent, AppfooterComponent, PdfGenerateComponent, DilogtopComponent, DilogshowdocumentsComponent, UserregistrationComponent, ReporttempComponent, PreviewshowComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FileUploadModule,
    ToastyModule.forRoot(),  
    MatSlideToggleModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatStepperModule,
    MatButtonModule,
    MatIconModule,
    AngularEditorModule,    
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    NgxFileDropModule,
    NgHttpLoaderModule.forRoot(),
    
   
    

  ],
  providers: [Httpclient],
  bootstrap: [AppComponent],
  exports:[ToastyModule]
})
export class AppModule { }
