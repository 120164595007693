import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import jspdf from 'jspdf';
import { Httpclient } from '../_services/authorization.service';

@Component({
  selector: 'app-previewshow',
  templateUrl: './previewshow.component.html',
  styleUrls: ['./previewshow.component.css']
})

export class PreviewshowComponent implements OnInit {
  @ViewChild('reportContent') reportContent: ElementRef;
  preivedatafinal:string;
  doclist2:[];
  doclist:any;
  doclist3:[];
  doclist4:[]; 
  doclist5:[];
  doclist6:[];
  doclist7:[];
  doclist8:[];
  doclist9:[];
  initialdata:firstdivdetails;
  matDialog: any;
  
  constructor(private router: Router,
    private http: Httpclient,
    public dialogRef: MatDialogRef<PreviewshowComponent>,
    @Inject(MAT_DIALOG_DATA) public values: appdetails) { }

  ngOnInit(): void {
    this.getpreviewfiledetails()
  }

  
  
  downloadPdf() {
        const doc = new jspdf();
        const specialElementHandlers = {
          '#editor': function (element, renderer) {
            return true;
          }
        };
    
        const content = this.reportContent.nativeElement;
    
        doc.html(document.body, {
          callback: function (doc) {
            doc.save('asdfghj' + '.pdf');
          }
    
        });
      } 


  showpdfpreview(): void {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PreviewshowComponent,{
      // width: '250px',
      
      data: {appid: this.values.appid       
            }
          });
        }
        
  getpreviewfiledetails()
  {

    let user ={
      //roleid:this.logindetails.username,
      appid:this.values.appid
      }
  


  return this.http.post("getpreviewdetails",user).toPromise().then(res => {
     
    const parseJson = JSON.stringify(res);
    const parsedJson = JSON.parse(parseJson);
    this.initialdata= parsedJson.data[0]; 
    this.doclist2 = parsedJson.data[2];
    this.doclist = parsedJson.data[1]; 
    this.doclist3 = parsedJson.data[3]; 
    this.doclist4 = parsedJson.data[4]; 
    this.doclist5 = parsedJson.data[5]; 
    this.doclist6 = parsedJson.data[6]; 
    this.doclist7 = parsedJson.data[7]; 
    this.doclist8 = parsedJson.data[8]; 
    this.doclist9 = parsedJson.data[9]; 
    debugger;
  });

  }
}
export interface appdetails {
  appid: number   
  
}

export interface firstdivdetails{
  
  sro:string;  
 village:string;
 name:string;
 branch:string
 docnoyear:string;
 pattasource:string;
 reuestcoments:string;
 nilamcomments:string;  
 survayno:string;
 plotno:string;
 propsize:string
 houseno:string;
 propaddres:string;
 northby:string;  
 southby:string;
 eastby:string;
 westby:string
 ecsource:string;
 ebsource:string;
 watersource:string
 propertytaxsource:string;
 receventmvcvvalue:string
 recenttransactiondate:string;
}
