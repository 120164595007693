	<!-- Main Header Nav -->
    <div class="container-fluid">
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">
            
            <a class="navbar-brand"  [routerLink]="['/home']">
                <img class="img-fluid" style="width: 253px;" src="../../../assets/images/nilaamLogo.png" alt="">
            
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-left: 10px;">
              <ul class="navbar-nav" style="margin-left: 10px; margin-right: auto;" >
                  <!-- <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/courses']">All Courses</a>
                  </li>  -->
                
              </ul>
               
              <form class="form-inline" >

                <i style="font-size: 18px;" class="pi pi-bell"></i>&nbsp;
                <div class="md-form my-0 mb35" style="padding: 0 10px;"> 
                    <img style="width: 40px;cursor: pointer;" title="Logout" (click)="logoff()" src="../../../assets/images/6.png"> 
                </div>
                <div class="md-form my-0 mb35 brdr_left_right_new1" style="color: #000;padding: 0 10px;border-left: 1px solid #383838;"> 
                    <a>
                      
                     <span class="dn-md"> Welcome Anbu</span>
                    </a>
                </div>
                 
    
                <div class="md-form my-0 mb35" style="padding: 0 10px;"> 
                    <img style="width: 40px;cursor: pointer;" title="Logout" (click)="logoff()" src="../../../assets/images/logout.png"> 
                </div>
              </form>
            </div>
          </nav>
        </div>