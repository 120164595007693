 

 <div  class="container-fluid" style="padding: 15px;padding-top: 0px;">
    <div class="row">  
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:10px;">
            <div class="bcrumbs" style="height: 40px; background-color: #bde7fa;border-radius: 2px;">
               
               <p style="margin-bottom: 0;">
                <i class="fa fa-tachometer" style="margin-left: 15px; margin-top: 10px; color: #000;" aria-hidden="true"> </i>
                <!-- Pdf - Upload Generate -->
                Dashboard / mark relevant transactions / preview
               </p>
              
               
            </div>
            </div>
            <div  style="width:100%">
              <button mat-raised-button color="warn" class="savebutton" (click)="draftpreviewsave()" >  save</button> 
        
                          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12"  style="padding-left:3%;padding-right:3%" id="toprintreportpdf">
                <table style="width: 100%;">
                  <tbody>
                    <tr>
                      <td colspan="3" style="background: lightgray;padding: 6px;">
                       Applicant Details
                      </td>
                    </tr>
                    <tr>
                     <td style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label >Name</label>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].name" class="form-control" />
                         </div>
                      
                     </td>
                     <td style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label  >branch</label>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].branch" class="form-control" />
                         </div>                        
                      </td>
                   </tr>
                    <tr>
                     <td colspan="3" style="background: lightgray;padding: 6px;">
                       property Details
                     </td>
                   </tr>
                   <tr>
                     <td style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label  >Doc no/Year</label>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].docno" class="form-control" />
                            <span style="color:black;font-weight:bolder;padding-left:3px;">/</span>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].docyear" class="form-control" />
                         </div>   
                       
                     </td>
                     <td style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label   >SRO</label>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].sro" class="form-control" />
                         </div>  
                       
                     </td>
                     <td style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label     >Village</label>
                            <input style="margin-left:10px; width: auto; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].village" class="form-control" />
                         </div>  
                       
                     </td>
                   </tr>
                          
                   <tr>
                     <td colspan="3" style="background: lightgray;vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label> requestor comments</label>
                            <input style="margin-left:10px; width: 65%; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].reuestcoments" class="form-control" />
                         </div> 
                       
                     </td>
                   </tr>
                   <tr>
                     <td colspan="3" style="vertical-align: middle;padding: 6px;">
                        <div class="form-row">
                            <label> nilam comments</label>
                            <input style="margin-left:10px; width: 65%; margin-left: 10px; height: 31px;" type="text"   [(ngModel)]="initialdata[0].nilamcomments" class="form-control" />
                         </div> 
                       
                         
                     </td>
                   </tr>
                 </tbody>
                </table> 
            </div>     
           
           <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
            <p style="font-weight: bold;color: darkturquoise;">Schedule of the Property  <mat-slide-toggle   [ngModel]="ispreviewshown" (click)="isshowprecviewmode()" style="margin-left:10px;color:black">preview </mat-slide-toggle> </p>
        
            <p *ngIf="ispreviewshown">{{preivedatafinal}}</p>

           <table style="width:100%" *ngIf="!ispreviewshown">
             <tbody>
               <tr>
                 <td colspan="2">
                   <div class="form-group">
                     <label >Survey Number</label>
                     <input [(ngModel)]="initialdata[0].survayno"  class="form-control" />           
                   </div>
                 </td>
                 <td>
                   <div class="form-group">
                     <label >Property Size</label>
                     <input [(ngModel)]="initialdata[0].propsize"  class="form-control" />           
                   </div>
                 </td>
               </tr>
                
               <tr>
                 <td>
                   <div class="form-group">
                     <label> Plot No</label>
                     <input [(ngModel)]="initialdata[0].plotno"  class="form-control" />           
                   </div>
                 </td>
                 <td>
                   <div class="form-group">
                     <label >House No</label>
                     <input [(ngModel)]="initialdata[0].houseno"  class="form-control" />           
                   </div>
                 </td>
                 <td rowspan="2">
                   <div class="form-group">
                     <label >Property Address</label>
                     <textarea  style="height:150px" [(ngModel)]="initialdata[0].propaddres"  class="form-control" ></textarea>         
                   </div>
                 </td>
               </tr>
               <tr>
                 <td>
                   <div class="form-group">
                     <label >North by</label>
                     <input [(ngModel)]="initialdata[0].northby"  class="form-control" />           
                   </div>
                 </td>
                 <td>
                   <div class="form-group">
                     <label >South by</label>
                     <input [(ngModel)]="initialdata[0].southby"  class="form-control" />           
                   </div>
                 </td>
               </tr>
               <tr>
                 <td>
                   <div class="form-group">
                     <label >East By</label>
                     <input [(ngModel)]="initialdata[0].eastby"  class="form-control" />           
                   </div>
                 </td>
                 <td>
                   <div class="form-group">
                     <label >west by</label>
                     <input [(ngModel)]="initialdata[0].westby"  class="form-control" />           
                   </div>
                 </td>
               </tr>
             </tbody>
           </table>
         </div>
         <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
         <p style="font-weight: bold;color: darkturquoise;">Tracing of Title: </p>
         <angular-editor [(ngModel)]="Devolution" [config]="config"  ></angular-editor>
         <p style="font-weight: bold;color: darkturquoise;">Conclusion</p>
         <angular-editor [(ngModel)]="Conclusion" [config]="config"  ></angular-editor>
         </div>

         <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%;padding-top:3%">
            <p style="font-weight: bold;color: darkturquoise;">List of documents used for scrutiny (scanned,photo copies) <button   (click)="addRowData('Add',{})"  mat-flat-button color="primary">Add Row</button> </p>
            
            <div style="max-height:200px;overflow-y:auto;width:100%">
            <table mat-table [dataSource]="dataSource1" #mytable class="my-table mat-elevation-z8" style="width:100%">
          
               
            
          
              <!-- Name Column -->
              <ng-container matColumnDef="docid">      
                <td mat-cell *matCellDef="let element" style="padding:20px;display: none;">   <input type="text" [(ngModel)]="element.docid"  class='form-control'> </td>
              </ng-container>
              <ng-container matColumnDef="textbox1">      
                <td mat-cell *matCellDef="let element" style="padding:20px">   <input type="text" [(ngModel)]="element.text1"  class='form-control'> </td>
              </ng-container>
              <ng-container matColumnDef="textbox2">      
                <td mat-cell *matCellDef="let element" style="padding:20px"><input type="text" [(ngModel)]="element.text2"  class='form-control'> </td>
              </ng-container>
              <ng-container matColumnDef="textbox3">      
                <td mat-cell *matCellDef="let element" style="padding:20px"> <input type="text" [(ngModel)]="element.text3"  class='form-control'> </td>
              </ng-container>
          
              <!-- Action Column -->
              <ng-container matColumnDef="action">
                
                <td mat-cell *matCellDef="let element; let j = index;" class="action-link" style="padding:20px"> 
                  <!-- <a (click)="openDialog('Update',element)">Edit</a> |  -->
                  <a (click)="deleteRowData('Delete',element,j)"><mat-icon>delete</mat-icon></a>  
                </td>
              </ng-container>
          
            
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
         </div>
        </div>
 
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">List of documents to be collected <button   mat-button  (click)="adddoccolected()"  mat-flat-button color="primary">Add Row</button></p>
          <table class="table table-bordered">             
            <tbody>
              <tr  *ngFor="let items of doclist2; let i = index">  
                <td><input type="text" [(ngModel)]="items.text1"  class='form-control'></td>               
                <td><input type="text" [(ngModel)]="items.text2"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="items.text3"  class='form-control' ></td>                  
                <td>  
                  <a (click)="deletedoccolected(i)"><mat-icon>delete</mat-icon></a>  </td>           
                     
              </tr>
            </tbody>
          </table>
        </div>
         

        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section I - Name from Different sources</p>
          <table class="table table-bordered">
            <thead>
              <tr class="heddercolortables">               
                <th>Source</th>
                <th>Name</th>
             
              </tr>
            </thead>
            <tbody>
              <tr>                 
                <td>E.C</td>
                <td><input type="text" [(ngModel)]="initialdata[0].ecsource"  class='form-control'></td>              
              </tr>
              <tr>                 
                <td>Patta</td>
                <td><input type="text" [(ngModel)]="initialdata[0].pattasource"  class='form-control'></td>              
              </tr>
              <tr>                 
                <td>Electricity,board</td>
                <td><input type="text" [(ngModel)]="initialdata[0].ebsource"  class='form-control'></td>              
              </tr>
              <tr>                 
                <td> Water Tax</td>
                <td><input type="text" [(ngModel)]="initialdata[0].watersource"  class='form-control'></td>              
              </tr>
              <tr>                 
                <td> Property Tax</td>
                <td><input type="text" [(ngModel)]="initialdata[0].propertytaxsource"  class='form-control'></td>              
              </tr>
              

            </tbody>
          </table>
        </div>

        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section II -  Property Details from Different dource <button  style="margin-left: 10px;" mat-button (click)="addTable()" mat-flat-button color="primary">addmore</button> </p>
   
          <table class="table table-bordered">
            <thead>
            <tr>
              <th class="heddercolortables">parameter</th>
              <th class="heddercolortables">By Ec</th>
              <th class="heddercolortables">By Patta</th>
              <th class="heddercolortables"></th>
            </tr>
          </thead>
            <tr *ngFor="let item of doclist3; let i = index">
              <td><input type="text" [(ngModel)]="item.parameter"  class='form-control' ></td>
              <td><input type="text"  [(ngModel)]="item.byec"  class='form-control' ></td>
              <td><input type="text" [(ngModel)]="item.bypatta"  class='form-control'></td>
              <td>  
                <a (click)="deleteRow(i)"><mat-icon>delete</mat-icon></a>  </td>
            </tr>
          </table>
 
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section III -  Most Recent Transaction Details</p>
          <table class="table table-bordered">
            <thead>
              
            </thead>
            <tbody>
              <tr> 
                <td>transaction date</td>  
                <td><input type="text" [(ngModel)]="initialdata[0].recenttransactiondate"  class='form-control'></td>                
                         
              </tr>
              <tr>  
                <td>MV/CV value</td>  
                <td><input type="text" [(ngModel)]="initialdata[0].receventmvcvvalue"  class='form-control'></td> 
                </tr>
             
               
              

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section IV - Guideline Value <button  style="margin-left: 10px;" mat-button (click)="addGuideline()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
            <thead>
              <tr class="heddercolortables">               
                <th>S.No  </th>
                <th>Survey Number/Street Name </th>
                <th>Classsification </th>
                <th>Guideline Value </th>
                <th></th>
             
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let item1 of doclist4; let i = index">  
                <td><input type="text" [(ngModel)]="item1.sno"  class='form-control'></td>               
                <td><input type="text" [(ngModel)]="item1.survarno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item1.guidlinevalue"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item1.lansclassifg"  class='form-control'></td>  
                <td>  
                  <a (click)="deleteGuideline(i)"><mat-icon>delete</mat-icon></a>  </td>           
                     
              </tr>
             
          
            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section V-A- Active Mortage as per EC <button  style="margin-left: 10px;" mat-button (click)="addActiveMortage()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
                      
            <thead>
              <tr class="heddercolortables">               
                <!-- <th>S.No  </th> -->
                <th>Executant Name </th>
                <th>Beneficiciary Name </th>
                <th>Date  </th>     
                <th>Doc.No </th>            
                <th>Survey Number</th>
                <th>MV/CV Value (Rs)</th>
                <th>Size of Transaction</th>  
                <th></th>            
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item2 of doclist5; let i = index">    
                <!-- <td><input type="text" [(ngModel)]="item2.sno"  class='form-control' ></td> -->
                <td><input type="text"  [(ngModel)]="item2.executantname"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item2.benefiname"  class='form-control'></td>  
                <td><input type="text" [(ngModel)]="item2.date"  class='form-control'></td> 
                <td><input type="text" [(ngModel)]="item2.docno"  class='form-control'></td>            
                <td><input type="text" [(ngModel)]="item2.surveynumber"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item2.mvcvvalue"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item2.sizeoftrans"  class='form-control'></td>             
                <td>  
                  <a (click)="deleteActiveMortage(i)"><mat-icon>delete</mat-icon></a>  </td>         
              </tr>

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section V-B- Active Lease as per EC <button  style="margin-left: 10px;" mat-button (click)="addActiveLease()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
                      
            <thead>
              <tr class="heddercolortables">               
                <!-- <th>S.No  </th> -->
                <th>Executant Name </th>
                <th>Beneficiciary Name </th>
                <th>Date </th>   
                <th>Doc.No </th>             
                <th>Survey Number</th>
                <th>MV/CV Value (Rs)</th>
                <th>Size of Transaction</th>    
                <th></th>         
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item3 of doclist6; let i = index">    
                <!-- <td><input type="text" [(ngModel)]="item3.sno"  class='form-control' ></td> -->
                <td><input type="text"  [(ngModel)]="item3.executantname"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item3.benefiname"  class='form-control'></td> 
                <td><input type="text" [(ngModel)]="item3.date"  class='form-control'></td>   
                <td><input type="text" [(ngModel)]="item3.docno"  class='form-control'></td>          
                <td><input type="text" [(ngModel)]="item3.surveynumber"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item3.mvcvvalue"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item3.sizeoftrans"  class='form-control'></td>    
                <td> <a (click)="deleteActiveLease(i)"><mat-icon>delete</mat-icon></a>  </td>           
                         
              </tr>

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="display:none; padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;"> section V-C- Closed Mortage as per EC <button  style="margin-left: 10px;" mat-button (click)="addClosedMortage()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
                      
            <thead>
              <tr class="heddercolortables">               
                <th>S.No  </th>
                <th>Executant Name </th>
                <th>Beneficiciary Name </th>
                <th>Date & Doc.No </th>               
                <th>Date of closure</th>
                <th>closure doc.no</th>
                   <th></th>      
              </tr>
            </thead>
            <tbody>
                         
              <tr *ngFor="let item3 of doclist7; let i = index">    
                <td><input type="text" [(ngModel)]="item3.sno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item3.executantname"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item3.benefiname"  class='form-control'></td>             
                <td><input type="text" [(ngModel)]="item3.datedocno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item3.dateclose"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item3.closedocno"  class='form-control'></td>  
                <td> <a (click)="delteClosedMortage(i)"><mat-icon>delete</mat-icon></a>  </td>            
                         
              </tr> 
                               
               

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="display:none;padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section V-D- Closed Lease as per EC <button  style="margin-left: 10px;" mat-button (click)="addClosedLease()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
                      
            <thead>
              <tr class="heddercolortables">               
                <th>S.No  </th>
                <th>Executant Name </th>
                <th>Beneficiciary Name </th>
                <th>Date & Doc.No </th>               
                <th>Date of closure</th>
                <th>closure doc.no</th>
                <th></th>
                         
              </tr>
            </thead>
            <tbody>
             
              <tr *ngFor="let item8 of doclist8; let i = index">    
                <td><input type="text" [(ngModel)]="item8.sno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item8.executantname"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item8.benefiname"  class='form-control'></td>             
                <td><input type="text" [(ngModel)]="item8.atedocno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item8.dateclose"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item8.closedocno"  class='form-control'></td> 
                <td>  
                  <a (click)="deleteClosedLease(i)"><mat-icon>delete</mat-icon></a>  </td>           
                         
              </tr> 
                               
               

            </tbody>
          </table>
        </div>

        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="display:none;padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section VI Observed Litigation as per EC <button  style="margin-left: 10px;" mat-button (click)="addLitigation()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
            
            <thead>
              <tr class="heddercolortables">               
                <th>S.No  </th>
                <th>Case Details </th>
                <th>Court Name & Address </th>
                <th>Petitioner</th>               
                <th>Responder</th>
                <th>Current status</th>
                <th></th>
                         
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item9 of doclist9; let i = index">    
                <td><input type="text" [(ngModel)]="item9.sno"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item9.casedet"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item9.courtname"  class='form-control'></td>             
                <td><input type="text" [(ngModel)]="item9.petionar"  class='form-control' ></td>
                <td><input type="text"  [(ngModel)]="item9.respondder"  class='form-control' ></td>
                <td><input type="text" [(ngModel)]="item9.corstatus"  class='form-control'></td>            
                <td> <a (click)="deleteLitigation(i)"><mat-icon>delete</mat-icon></a>  </td>        
              </tr> 

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section VII-A Chain of Ownership</p>
          <table class="table table-bordered">
             
            <tbody>
              <tr>              
                    <td></td>       
                         
              </tr>

            </tbody>
          </table>
        </div>

        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">section VII-B Detailed History Of TransactionsC <button  style="margin-left: 10px;" mat-button (click)="addEC2()" mat-flat-button color="primary">addmore</button></p>
          <table class="table table-bordered">
 
 
            <thead>
              <tr class="heddercolortables">               
                <!-- <th>S.No  </th> -->
                <th> Executant(s) Name</th>
                <th> Claimantt(s) Name </th>
                <th>Registration Date</th>               
                <th>Transaction Date</th>
                <th>MV/CV value (Rs.)</th>
                <th>Doc.No</th>  
                <th>Year</th>               
                <th>Survay No</th>
                <th>SRO</th>
                <th>schedules</th>     
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item10 of doclist10; let i = index">                
                <!-- <td rowspan="3" style="vertical-align: middle;"><input type="text"   class='form-control'></td>     -->
                <td><input type="text"  [(ngModel)]="item10.excutant" class='form-control'></td>  
                <td><input type="text"  [(ngModel)]="item10.climant" class='form-control'></td>  
                <td><input type="text"   [(ngModel)]="item10.regdate" class='form-control'></td> 
                <td><input type="text"   [(ngModel)]="item10.trandate" class='form-control'></td> 
                <td><input type="text" [(ngModel)]="item10.mvcval"  class='form-control'></td>  
                <td><input type="text"  [(ngModel)]="item10.docno" class='form-control'></td>  
                <td><input type="text"  [(ngModel)]="item10.year"  class='form-control'></td>                                
                <td><input type="text"  [(ngModel)]="item10.surveyno"  class='form-control'></td> 
                <td><input type="text"  [(ngModel)]="item10.sro"  class='form-control'></td>   
                <td><input type="text"  [(ngModel)]="item10.schedulenos"  class='form-control'></td>       
              </tr>
              <tr>
             
                           

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">Section VIII Encumbarance Certificate(E.Cs)</p>
          <table class="table table-bordered">
              
            <tbody>
              <tr>
                <td><input #fileec type="file" accept='image/*' /></td> 
                <td colspan="2"><button  style="margin-left: 10px;" mat-button (click)="ecchanageimage(fileec.files)" mat-flat-button color="primary">add</button></td></tr>
                                                       
                <tr *ngFor="let im1 of EC2imageslist; let i = index">            
                <td>{{im1.filename}}</td>                         
                 </tr>
              

            </tbody>
          </table>
        </div>
        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
          <p style="font-weight: bold;color: darkturquoise;">Section IX Patta</p>
          <table class="table table-bordered">
             
            <tbody>
              <tr>              
                    <td><input #filepatta type="file" accept='image/*' (change)="preview($event.target.files,filepatta.files,1)" /></td>                           
                         
              </tr>
              <tr>
                <td style="text-align: center;"><img [src]="imgURLpatta" height="200" *ngIf="imgURLpatta"></td> 
              </tr>

            </tbody>
          </table>
        </div>
      </div>

        <div  class="col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left:3%;padding-right:3%">
           
          <table class="table table-bordered">
             
            <tbody>
              <tr>
                <td colspan="2">remarks</td>
              </tr>
              <tr>   
                <td>
                  <div class="form-group">
                    <label >reason</label>
                    <input   type="text" class="form-control" class="form-control" />           
                  </div>
                </td>           
                    <td>
                      <div class="form-group">
                        <label >remark</label>
                        <input   type="text" class="form-control" class="form-control" />           
                      </div>
                    </td>       
                         
              </tr>
              <tr>   
                <td colspan="2" style="text-align: center;">
                  <div class="example-button-row">
                                      
                    <button mat-raised-button>Word</button>
                   
                    <button mat-raised-button color="primary"  (click)="navigatetopreview()" style="margin-left: 10px;">pdf</button>
                    <button mat-raised-button color="accent" style="margin-left: 10px;">Submit RFI</button>
                    <button mat-raised-button color="warn" (click)="finalsubmit()" style="margin-left: 10px;">Submit</button>
                   
                    
                  </div>
                </td>       
                         
              </tr>


            </tbody>
          </table>
        </div>
        
    </div>
   
  </div>